import { API } from "aws-amplify";

export const GET_STATES_STARTED = "GET_STATES_STARTED";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export function getStatesBySport(sport) {
  console.log("getting states for: ", sport);

  return async dispatch => {
    try {
      dispatch(getStatesStarted(sport));
      const states = await API.get("recruiterAPI", `states/${sport}`);
      console.log("got states for:", sport);
      dispatch(getStatesSuccess(sport, states));
    } catch (e) {
      console.log("problem getting states:", e);
      dispatch(getStatesFailure(e));
    }
  };
}

const getStatesSuccess = (sport, data = []) => ({
  type: GET_STATES_SUCCESS,
  payload: { sport, data },
});

const getStatesStarted = sport => ({
  type: GET_STATES_STARTED,
  payload: sport,
});

const getStatesFailure = error => ({
  type: GET_STATES_FAILURE,
  payload: {
    error,
  },
});
