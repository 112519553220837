import { API } from "aws-amplify";

export const GET_RECRUITER_PLAYER_PIPELINE_HISTORY_STARTED =
  "GET_RECRUITER_PLAYER_PIPELINE_HISTORY_STARTED";
export const GET_RECRUITER_PLAYER_PIPELINE_HISTORY_SUCCESS =
  "GET_RECRUITER_PLAYER_PIPELINE_HISTORY_SUCCESS";
export const GET_RECRUITER_PLAYER_PIPELINE_HISTORY_FAILURE =
  "GET_RECRUITER_PLAYER_PIPELINE_HISTORY_FAILURE";

export function getRecruiterPlayerPipelineHistory() {
  console.log("getting recruiter player pipeline history");
  return async dispatch => {
    try {
      dispatch(getRecruiterPlayerPipelineHistoryStarted());
      const playerPipelineHistory = await API.get(
        "recruiterAPI",
        `playerPipelineHistory`,
      );
      console.log("got recruiter player pipeline history");
      return dispatch(
        getRecruiterPlayerPipelineHistorySuccess(playerPipelineHistory),
      );
    } catch (e) {
      console.log("problem getting recruiter player notes:", e);
      return dispatch(getRecruiterPlayerPipelineHistoryFailure(e));
    }
  };
}

const getRecruiterPlayerPipelineHistorySuccess = (apiRes = {}) => ({
  type: GET_RECRUITER_PLAYER_PIPELINE_HISTORY_SUCCESS,
  payload: {
    playerPipelineHistory: [...apiRes],
  },
});

const getRecruiterPlayerPipelineHistoryStarted = () => ({
  type: GET_RECRUITER_PLAYER_PIPELINE_HISTORY_STARTED,
});

const getRecruiterPlayerPipelineHistoryFailure = error => ({
  type: GET_RECRUITER_PLAYER_PIPELINE_HISTORY_FAILURE,
  payload: {
    error,
  },
});
