import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import Signout from "../navs/signOut";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/user_slice";

const Home = props => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }
  }, [dispatch, userData.userId]);

  if (userData && userData.userGroups && userData.userGroups[0])
    navigate("/" + userData.userGroups[0]);
  else navigate("/referrals");

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!userInfo) {
      Auth.currentAuthenticatedUser().then(data => {
        setUserInfo(data);
        let groups =
          data.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups && groups.length === 1 && groups[0] !== "admins")
          navigate("/" + groups[0]);
      });
    }
  });

  return (
    <div className="home">
      <h1>Welcome</h1>
      <p>Hello, and thank you for choosing Playmaker!</p>
      <p>
        As part of keeping our platform secure and providing the best possible
        experience, users are asked to go through a simple confirmation process.
        You should receive communication from us shortly regarding your
        verification. After this short check, you will be granted access to the
        full platform and tools available at Playmaker.
      </p>
      <p>
        Please allow 1 business day for this verification process. If it has
        been longer than 48 hours, please feel free to reach out to us at
        verification@playmakerrecruiting.com and we will be happy to assist you.
      </p>
      <p>Thank You,</p>
      <p>Playmaker Recruiting Team</p>
      <Signout />
    </div>
  );
};

export default Home;
