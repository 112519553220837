import React, { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRosterId,
  selectCurrentSeasonId,
  selectRosterPlayersByRosterId,
} from "../redux/selectors/rosterSelectors";
import {
  getRosterPlayersByRosterId,
  getRosterPlayersBySeasonId,
} from "../redux/actions/rosterActions";

const { Option } = Select;

export const PlayerSelect = ({
  onChange,
  width,
  mode = "default",
  rosterId,
  seasonId,
  value,
}) => {
  const currentRosterIdInStore = useSelector(selectCurrentRosterId);
  const currentSeasonIdInStore = useSelector(selectCurrentSeasonId);
  const players = useSelector(
    selectRosterPlayersByRosterId(rosterId ? rosterId : "all"),
  );
  const searchOptions = players
    ? players
        .sort((p1, p2) =>
          p1.playerName
            .toLowerCase()
            .localeCompare(p2.playerName.toLowerCase()),
        )
        .map(d => (
          <Option key={d.playerId} name={d.playerName}>
            {d.playerName} ({d.position})
          </Option>
        ))
    : [];

  const loadingPlayers = useSelector(store => store.rosterData.loadingPlayers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!rosterId && rosterId !== currentRosterIdInStore && !loadingPlayers) {
      dispatch(getRosterPlayersByRosterId(rosterId));
    } else if (
      !!seasonId &&
      seasonId !== currentSeasonIdInStore &&
      !loadingPlayers
    ) {
      dispatch(getRosterPlayersBySeasonId(seasonId));
    }
  });

  return (
    <Select
      showSearch={true}
      allowClear
      placeholder="Select a Player"
      mode={mode}
      style={!!width ? { width } : {}}
      onChange={onChange}
      value={value}
      filterOption={(input, option) => {
        return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {searchOptions}
    </Select>
  );
};
