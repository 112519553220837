import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { getPlayerstatsByPlayerId } from "../../redux/actions/playerstatsActions";
import { format } from "date-fns";
import { Layout, Table } from "antd";
import statGroupings from "../../shared-components/categories_fb";
import {
  selectSeasonsBySport,
  selectSeasonsLoading,
} from "../../redux/season_slice";
import { getSeasonsBySport } from "../../redux/season_slice";
import "./custom-styles.css";

const GamestatsTable = ({ playerId, statGrouping, sport }) => {
  const data = useSelector(selectPlayerstatsByPlayerId(playerId));
  const loading = useSelector(store => store.playerstatsData.loading);

  const seasonsData = useSelector(selectSeasonsBySport(sport));
  const seasonsLoading = useSelector(selectSeasonsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !data) {
      dispatch(getPlayerstatsByPlayerId(playerId));
    }

    if (!seasonsData && !seasonsLoading) dispatch(getSeasonsBySport(sport));
  });

  const sg = statGroupings[statGrouping];

  const seasonColumns = [
    { title: "Season/Game", dataIndex: "rowName", key: "rowId" },
    { title: "Position", dataIndex: "position", key: "position" },
  ].concat(
    statGroupings[statGrouping].stats.map(s => {
      return { title: s, dataIndex: sg.statPrefix + s, key: s };
    }),
  );

  if (data) {
    const gameStats = data.game_stats_fb
      .filter(r => sg.keyStats.some(s => parseInt(r[sg.statPrefix + s])) > 0)
      .map(r => {
        return {
          ...r,
          rowId: r.gameId,
          rowName:
            format(new Date(r.date), "M/d/yyyy") +
            (r.gameType === "home" ? " vs " : " @ ") +
            r.opposingSchoolName +
            " " +
            r.opposingTeamName,
        };
      });

    const seasonStats = data.season_stats_fb
      .filter(r => sg.keyStats.some(s => parseInt(r[sg.statPrefix + s])) > 0)
      .map(r => {
        return {
          ...r,
          rowId: r.seasonId,
          rowName: seasonsData
            ? seasonsData.find(s => s.seasonId === r.seasonId).name
            : r.seasonId,
          children: gameStats.filter(g => g.seasonId === r.seasonId),
        };
      });

    if (gameStats.length > 0 || data.season_stats_fb[0]) {
      return (
        <Layout.Content
          style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}
        >
          <Table
            dataSource={seasonStats}
            rowKey="rowId"
            columns={seasonColumns}
            loading={loading}
            size="small"
            pagination={false}
            rowClassName={r => (r.gameId ? "game-stat-row" : "season-stat-row")}
          />
        </Layout.Content>
      );
    }
  }

  return null;
};

export default GamestatsTable;
