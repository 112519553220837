export const selectQuicksearchState = store => store.quicksearchData;

export const selectQuicksearchData = store =>
  selectQuicksearchState(store)
    ? selectQuicksearchState(store).searchResults
    : [];

export const selectQuicksearchLoading = store =>
  selectQuicksearchState(store) ? selectQuicksearchState(store).loading : false;

export const selectQuicksearchCurrentQuery = store =>
  selectQuicksearchState(store)
    ? selectQuicksearchState(store).currentQuery
    : null;
