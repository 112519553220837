export const selectRosterState = store => store.rosterData;

const selectRosterPlayerData = store => {
  if (selectRosterState(store)) {
    return selectRosterState(store).rosterPlayerData;
  } else {
    return null;
  }
};

export const selectCurrentRosterId = store => {
  return selectRosterState(store)
    ? selectRosterState(store).currentRosterId
    : null;
};

export const selectCurrentSeasonId = store => {
  return selectRosterState(store)
    ? selectRosterState(store).currentSeasonId
    : null;
};

export const selectRosterPlayersByRosterId = rosterId => store =>
  selectRosterPlayerData(store)
    ? selectRosterPlayerData(store)
        .filter(p => rosterId === "all" || p.rosterId === rosterId)
        .map(p => {
          return { ...p, key: p.playerId };
        })
    : null;

export const selectRosterPlayersByRosterIdAndPosition =
  (rosterId, position) => store => {
    try {
      return selectRosterState(store).rosterPlayerDataByPosition[rosterId][
        position
      ];
    } catch (e) {
      return [];
    }
  };

export const selectRosterPlayersByRosterIdGroupedByPosition =
  rosterId => store => {
    if (rosterId && selectRosterPlayerData(store)) {
      return selectRosterState(store).rosterPlayerDataByPosition[rosterId];
    } else {
      return [];
    }
  };

export const selectRosterTeamsBySeasonId = seasonId => store => {
  try {
    return selectRosterState(store).rosterTeamDataBySeasonId[seasonId];
  } catch (e) {
    return [];
  }
};

export const selectRosterFromTeamsBySeasonId =
  (seasondId, rosterId) => store => {
    const rosterTeamData = selectRosterTeamsBySeasonId(seasondId)(store);
    return rosterTeamData
      ? rosterTeamData.find(r => r.rosterId === rosterId)
      : null;
  };
