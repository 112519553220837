import {
  POST_GAMEPLAYS_FAILURE,
  POST_GAMEPLAYS_STARTED,
  POST_GAMEPLAYS_SUCCESS,
} from "../actions/gamePlaysActions";

const initialState = {
  currentGameId: null,
  syncing: false,
  error: null,
};

export default function gamePlaysReducer(state = initialState, action) {
  switch (action.type) {
    case POST_GAMEPLAYS_STARTED:
      return {
        ...state,
        syncing: true,
        error: null,
        currentGameId: action.payload.gameId,
      };
    case POST_GAMEPLAYS_SUCCESS:
      return {
        ...state,
        error: null,
        syncing: false,
      };
    case POST_GAMEPLAYS_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
