import {
  FlatfileProvider,
  Sheet,
  useFlatfile,
  useEvent,
} from "@flatfile/react";
import api from "@flatfile/api";
import { Button, Modal } from "antd";
import config from "./../config";
import React, { createContext } from "react";

const ReachableContext = createContext(null);

const getSheetData = records => {
  return records.map(r =>
    Object.fromEntries(Object.entries(r.values).map(([k, v]) => [k, v.value])),
  );
};

const FlatfilePortal = ({
  buttonContent,
  blueprint,
  handleSubmit,
  handleRecord,
  ...otherProps
}) => {
  const { openPortal } = useFlatfile();
  const [modal, contextHolder] = Modal.useModal();

  const blueprintWithActions = {
    ...blueprint,
    actions: [
      {
        operation: "submitActionFg",
        mode: "foreground",
        label: "Submit " + blueprint.name,
        type: "string",
        description: "Submit " + blueprint.name,
        primary: true,
        // constraints: [{ type: 'hasData' }],
      },
    ],
  };

  useEvent("job:ready", { job: "sheet:submitActionFg" }, async event => {
    const { jobId, sheetId } = event.context;

    try {
      await api.jobs.ack(jobId, {
        info: "Getting started.",
        progress: 10,
      });

      // const { records } = await event.data;
      const { data } = await api.records.get(sheetId);
      const validRecords = data.records.filter(r => r.valid);
      const errorRecords = data.records.filter(r => !r.valid);

      if (errorRecords.length > 0) {
        const response = await modal.confirm({
          title: "Data Errors",
          content: (
            <>
              Your submission includes {validRecords.length} valid row(s) and{" "}
              {errorRecords.length} row(s) with errors.
              <br />
              If these rows are not necessary or valid, then click "Submit",
              otherwise correct errors.
            </>
          ),
          okText: "Submit valid rows",
          cancelText: "Correct errors",
        });

        if (!response) throw new Error("Correcting errors");
      }

      if (validRecords.length > 0) {
        handleSubmit(getSheetData(validRecords));
      } else {
        throw new Error("No valid records.");
      }

      await api.jobs.complete(jobId, {
        outcome: {
          message: "Records submitted.",
        },
      });
    } catch (error) {
      console.error("Error:", error);

      await api.jobs.fail(jobId, {
        outcome: {
          message: "No records submitted.",
        },
      });
    }
  });

  return (
    <ReachableContext.Provider value="Light">
      <Button type={"primary"} onClick={openPortal}>
        {buttonContent}
      </Button>
      <Sheet
        config={blueprintWithActions}
        onRecordHook={handleRecord}
        submitSettings={{ deleteSpaceAfterSubmit: true }}
      />
      {contextHolder}
    </ReachableContext.Provider>
  );
};

const FlatfileWrapper = ({
  buttonContent,
  blueprint,
  handleSubmit,
  handleRecord,
}) => {
  return (
    <FlatfileProvider
      publishableKey={config.flatfile.publishableKey}
      config={{
        closeSpace: true,
      }}
    >
      <FlatfilePortal
        buttonContent={buttonContent}
        blueprint={blueprint}
        handleSubmit={handleSubmit}
        handleRecord={handleRecord}
      />
    </FlatfileProvider>
  );
};

export default FlatfileWrapper;
