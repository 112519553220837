import { API } from "aws-amplify";

export const GET_PLAYER_LINKS_STARTED = "GET_PLAYER_LINKS_STARTED";
export const GET_PLAYER_LINKS_SUCCESS = "GET_PLAYER_LINKS_SUCCESS";
export const GET_PLAYER_LINKS_FAILURE = "GET_PLAYER_LINKS_FAILURE";

export const PUT_PLAYER_LINKS_STARTED = "PUT_PLAYER_LINKS_STARTED";
export const PUT_PLAYER_LINKS_SUCCESS = "PUT_PLAYER_LINKS_SUCCESS";
export const PUT_PLAYER_LINKS_FAILURE = "PUT_PLAYER_LINKS_FAILURE";

export const GET_PLAYER_ABOUTS_STARTED = "GET_PLAYER_ABOUTS_STARTED";
export const GET_PLAYER_ABOUTS_SUCCESS = "GET_PLAYER_ABOUTS_SUCCESS";
export const GET_PLAYER_ABOUTS_FAILURE = "GET_PLAYER_ABOUTS_FAILURE";

export const PUT_PLAYER_ABOUTS_STARTED = "PUT_PLAYER_ABOUTS_STARTED";
export const PUT_PLAYER_ABOUTS_SUCCESS = "PUT_PLAYER_ABOUTS_SUCCESS";
export const PUT_PLAYER_ABOUTS_FAILURE = "PUT_PLAYER_ABOUTS_FAILURE";

export const GET_MY_PLAYERIDS_STARTED = "GET_MY_PLAYERIDS_STARTED";
export const GET_MY_PLAYERIDS_SUCCESS = "GET_MY_PLAYERIDS_SUCCESS";
export const GET_MY_PLAYERIDS_FAILURE = "GET_MY_PLAYERIDS_FAILURE";

export const GET_PLAYER_PROFILEDATA_STARTED = "GET_PLAYER_PROFILEDATA_STARTED";
export const GET_PLAYER_PROFILEDATA_SUCCESS = "GET_PLAYER_PROFILEDATA_SUCCESS";
export const GET_PLAYER_PROFILEDATA_FAILURE = "GET_PLAYER_PROFILEDATA_FAILURE";
export const CLEAR_PLAYER_PROFILEDATA = "CLEAR_PLAYER_PROFILEDATA";

export const PUT_PLAYER_CONTACT_DETAILS_STARTED =
  "PUT_PLAYER_CONTACT_DETAILS_STARTED";
export const PUT_PLAYER_CONTACT_DETAILS_SUCCESS =
  "PUT_PLAYER_CONTACT_DETAILS_SUCCESS";
export const PUT_PLAYER_CONTACT_DETAILS_FAILURE =
  "PUT_PLAYER_CONTACT_DETAILS_FAILURE";

export function getPlayerLinksByPlayerId(playerId) {
  console.log("getting player links for: ", playerId);

  return async dispatch => {
    try {
      dispatch(getPlayerLinksStarted(playerId));
      const playerLinks = await API.get(
        "playerAPI",
        `player-links/${playerId}`,
      );
      console.log("got player links for:", playerId);
      return dispatch(getPlayerLinksSuccess(playerId, playerLinks));
    } catch (e) {
      console.log("problem getting player links:", e);
      return dispatch(getPlayerLinksFailure(e));
    }
  };
}

const getPlayerLinksSuccess = (playerId, data = []) => ({
  type: GET_PLAYER_LINKS_SUCCESS,
  payload: { playerId, data },
});

const getPlayerLinksStarted = playerId => ({
  type: GET_PLAYER_LINKS_STARTED,
  payload: playerId,
});

const getPlayerLinksFailure = error => ({
  type: GET_PLAYER_LINKS_FAILURE,
  payload: {
    error,
  },
});

export function putPlayerLinksByPlayerId(playerId, links) {
  console.log("putting player links for: ", playerId);

  return async dispatch => {
    try {
      dispatch(putPlayerLinksStarted(playerId, links));
      await API.post("playerAPI", `player-links/${playerId}`, {
        body: links,
      });
      console.log("put player links for:", playerId);
      return dispatch(putPlayerLinksSuccess(playerId));
    } catch (e) {
      console.log("problem putting player links:", e);
      return dispatch(putPlayerLinksFailure(e));
    }
  };
}

const putPlayerLinksSuccess = playerId => ({
  type: PUT_PLAYER_LINKS_SUCCESS,
  payload: playerId,
});

const putPlayerLinksStarted = (playerId, data) => ({
  type: PUT_PLAYER_LINKS_STARTED,
  payload: { playerId, data },
});

const putPlayerLinksFailure = error => ({
  type: PUT_PLAYER_LINKS_FAILURE,
  payload: {
    error,
  },
});

export function getPlayerAboutsByPlayerId(playerId) {
  console.log("getting player abouts for: ", playerId);

  return async dispatch => {
    try {
      dispatch(getPlayerAboutsStarted(playerId));
      const playerAbouts = await API.get(
        "playerAPI",
        `player-abouts/${playerId}`,
      );
      console.log("got player abouts for:", playerId);
      return dispatch(getPlayerAboutsSuccess(playerId, playerAbouts));
    } catch (e) {
      console.log("problem getting player abouts:", e);
      return dispatch(getPlayerAboutsFailure(e));
    }
  };
}

const getPlayerAboutsSuccess = (playerId, data = []) => ({
  type: GET_PLAYER_ABOUTS_SUCCESS,
  payload: { playerId, data },
});

const getPlayerAboutsStarted = playerId => ({
  type: GET_PLAYER_ABOUTS_STARTED,
  payload: playerId,
});

const getPlayerAboutsFailure = error => ({
  type: GET_PLAYER_ABOUTS_FAILURE,
  payload: {
    error,
  },
});

export function putPlayerAboutsByPlayerId(playerId, abouts) {
  console.log("putting player abouts for: ", playerId);

  return async dispatch => {
    try {
      dispatch(putPlayerAboutsStarted(playerId, abouts));
      await API.post("playerAPI", `player-abouts/${playerId}`, {
        body: abouts,
      });
      console.log("put player abouts for:", playerId);
      return dispatch(putPlayerAboutsSuccess(playerId));
    } catch (e) {
      console.log("problem putting player abouts:", e);
      return dispatch(putPlayerAboutsFailure(e));
    }
  };
}

export function putPlayerContactDetailsByPlayerId(playerId, contactDetails) {
  console.log("putting player contact details for: ", playerId);

  return async dispatch => {
    try {
      dispatch(putPlayerContactDetailsStarted(playerId, contactDetails));
      await API.put("playerAPI", `contact-info/${playerId}`, {
        body: contactDetails,
      });
      console.log("put player contact details for:", playerId);
      return dispatch(putPlayerContactDetailsSuccess(playerId));
    } catch (e) {
      console.log("problem putting player contact details:", e);
      return dispatch(putPlayerContactDetailsFailure(e));
    }
  };
}

const putPlayerContactDetailsSuccess = playerId => ({
  type: PUT_PLAYER_CONTACT_DETAILS_SUCCESS,
  payload: playerId,
});

const putPlayerContactDetailsStarted = (playerId, data) => ({
  type: PUT_PLAYER_CONTACT_DETAILS_STARTED,
  payload: { playerId, data },
});

const putPlayerContactDetailsFailure = error => ({
  type: PUT_PLAYER_CONTACT_DETAILS_FAILURE,
  payload: {
    error,
  },
});

const putPlayerAboutsSuccess = playerId => ({
  type: PUT_PLAYER_ABOUTS_SUCCESS,
  payload: playerId,
});

const putPlayerAboutsStarted = (playerId, data) => ({
  type: PUT_PLAYER_ABOUTS_STARTED,
  payload: { playerId, data },
});

const putPlayerAboutsFailure = error => ({
  type: PUT_PLAYER_ABOUTS_FAILURE,
  payload: {
    error,
  },
});

export function getMyPlayerIds() {
  console.log("getting my playerIds");

  return async dispatch => {
    try {
      dispatch(getMyPlayerIdsStarted());
      const ids = await API.get("playerAPI", `my-player-ids`);
      console.log("got my player ids");
      return dispatch(getMyPlayerIdsSuccess(ids));
    } catch (e) {
      console.log("problem getting my player ids:", e);
      return dispatch(getMyPlayerIdsFailure(e));
    }
  };
}

const getMyPlayerIdsSuccess = (data = []) => ({
  type: GET_MY_PLAYERIDS_SUCCESS,
  payload: { data },
});

const getMyPlayerIdsStarted = () => ({
  type: GET_MY_PLAYERIDS_STARTED,
});

const getMyPlayerIdsFailure = error => ({
  type: GET_MY_PLAYERIDS_FAILURE,
  payload: {
    error,
  },
});

export function clearPlayerProfiledata(playerId) {
  return {
    type: CLEAR_PLAYER_PROFILEDATA,
    payload: { playerId },
  };
}

export function getPlayerProfiledataByPlayerId(playerId) {
  console.log("getting player Profiledata for: ", playerId);

  return async dispatch => {
    try {
      dispatch(getPlayerProfiledataStarted(playerId));
      const profileData = await API.get(
        "playerAPI",
        `profile-data/${playerId}`,
      );
      console.log("got profiledata for:", playerId);
      return dispatch(getPlayerProfiledataSuccess(playerId, profileData));
    } catch (e) {
      console.log("problem getting profiledata:", e);
      return dispatch(getPlayerProfiledataFailure(e));
    }
  };
}

const getPlayerProfiledataSuccess = (playerId, data = []) => ({
  type: GET_PLAYER_PROFILEDATA_SUCCESS,
  payload: { playerId, data },
});

const getPlayerProfiledataStarted = playerId => ({
  type: GET_PLAYER_PROFILEDATA_STARTED,
  payload: playerId,
});

const getPlayerProfiledataFailure = error => ({
  type: GET_PLAYER_PROFILEDATA_FAILURE,
  payload: {
    error,
  },
});
