import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { message } from "antd";

const slice = createSlice({
  name: "customAnalyticsFilter",
  initialState: {
    loading: false,
    error: null,
    dataByCoachId: null,
    dataRefresh: false,
  },
  reducers: {
    analyticsFilterLoading: (state, action) => {
      state.loading = true;
    },
    getAnalyticsFilterSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.dataByCoachId = action.payload.data;
      state.dataRefresh = false;
    },
    getAnalyticsFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createAnalyticsFilterSuccess: (state, action) => {
      message.success(`saved analytics filter has been created`, 2);
      state.loading = false;
      state.error = null;
      state.dataRefresh = true;
    },
    updateAnalyticsFilterSuccess: (state, action) => {
      message.success(`saved analytics filter has been updated`, 2);
      state.loading = false;
      state.error = null;
      state.dataRefresh = true;
    },
    deleteAnalyticsFilterSuccess: (state, action) => {
      message.success(`saved analytics filter has been delete`, 2);
      state.loading = false;
      state.error = null;
      state.dataRefresh = true;
    },
    createAnalyticsFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});
export default slice.reducer;

// Actions
export const {
  analyticsFilterLoading,
  getAnalyticsFilterSuccess,
  getAnalyticsFilterFailure,
  createAnalyticsFilterSuccess,
  updateAnalyticsFilterSuccess,
  deleteAnalyticsFilterSuccess,
  createAnalyticsFilterFailure,
} = slice.actions;

export const getCustomAnalyticsFilters = () => async dispatch => {
  try {
    dispatch(analyticsFilterLoading());
    const data = await API.get(
      "coachAPI",
      `custom-game-analytics/savedFilters`,
    );

    console.log("got custom analytics filter for coachId");
    dispatch(getAnalyticsFilterSuccess({ data: data }));
  } catch (e) {
    console.log("problem getting custom analytics filter:", e);
    dispatch(getAnalyticsFilterFailure(e));
  }
};

export const deleteCustomAnalyticsFilter = filterId => async dispatch => {
  try {
    dispatch(analyticsFilterLoading());
    await API.del("coachAPI", `custom-game-analytics/savedFilter/${filterId}`);

    console.log(`delete custom analytics filter: ${filterId}`);
    dispatch(deleteAnalyticsFilterSuccess({ filterId: filterId }));
  } catch (e) {
    console.log("problem deleting custom analytics filter:", e);
    dispatch(createAnalyticsFilterFailure(e));
  }
};

export const createOrUpdateCustomAnalyticsFilter =
  postData => async dispatch => {
    try {
      dispatch(analyticsFilterLoading(postData));
      const data = await API.post(
        "coachAPI",
        `custom-game-analytics/savedFilter`,
        {
          body: postData,
        },
      );

      if (postData.filterId) {
        console.log("update custom analytics filter for:", data.filterId);
        dispatch(updateAnalyticsFilterSuccess(data.filterId));
      } else {
        console.log("created custom analytics filter for:", data.filterId);
        dispatch(createAnalyticsFilterSuccess(data.filterId));
      }
    } catch (e) {
      console.log("problem creating custom analytics filter:", e);
      dispatch(createAnalyticsFilterFailure(e));
    }
  };

export const selectFilterDataLoading = store =>
  store.customAnalyticsFilter.loading;
export const selectFilterDataError = store => store.customAnalyticsFilter.error;
export const selectFilterDataRefresh = store =>
  store.customAnalyticsFilter.dataRefresh;
export const selectFilterData = () => store => {
  return store.customAnalyticsFilter.dataByCoachId;
};
