import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Layout } from "antd";
import { getSentMessages } from "../../redux/actions/messageActions";
import { navigate } from "@reach/router";

const sorter = key => (a, b) =>
  typeof a[key] === "string" ? a[key].localeCompare(b[key]) : a[key] - b[key];

const Outbox = props => {
  const dispatch = useDispatch();
  const sentMessages = useSelector(store => store.messageData.sentMessages);
  const loading = useSelector(store => store.messageData.loading);

  const columns = [
    {
      title: "Sent On",
      dataIndex: "sentOn",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("sentOn"),
    },
    {
      title: "Recipient",
      dataIndex: "user_name",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("user_name"),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("subject"),
    },
  ];

  useEffect(() => {
    if (!loading && !sentMessages) {
      dispatch(getSentMessages());
    }
  });

  return (
    <Layout>
      <h1>Sent Messages</h1>
      <Layout.Content style={{ marginRight: 40 }}>
        <Table
          dataSource={sentMessages}
          rowKey="messageId"
          columns={columns}
          loading={loading}
          //scroll={{ y: 600, x: 4000 }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: 15,
          }}
          size="small"
          onRow={rec => ({
            onDoubleClick: () => {
              navigate("/comm/messages/" + rec.messageId);
            },
          })}
        ></Table>
      </Layout.Content>
    </Layout>
  );
};

export default Outbox;
