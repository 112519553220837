import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoachData,
  getSchoolProfile,
  putSchoolProfile,
} from "../../redux/actions/schoolProfileActions";
import {
  selectCoachData,
  selectCoachDataLoading,
  selectCurrentSchoolId,
  selectSchoolProfile,
  selectSchoolProfilesLoading,
} from "../../redux/selectors/schoolProfilesSelectors";
import { Button, Form, Input, message, Space } from "antd";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import * as queryString from "query-string";
import { useLocation, useNavigate } from "@reach/router";

const DisplayItem = ({ label, value }) => {
  return (
    <p>
      <label>{label}:</label> {value}
    </p>
  );
};
const SchoolProfileView = ({ schoolId, data }) => {
  if (data) {
    return (
      <Space direction="vertical">
        <DisplayItem value={data.schoolName} label="School Name" />
        <DisplayItem value={data.mascot} label="School Mascot" />
        <DisplayItem value={data.address} label="School Address" />
        <DisplayItem value={data.city} label="School City" />
        <DisplayItem value={data.state} label="School State" />
        <DisplayItem value={data.zipCode} label="School Zip" />
        <DisplayItem value={data.conference} label="Conference" />
        <DisplayItem value={data.division} label="Division" />
        <DisplayItem value={data.region} label="Region" />
        <DisplayItem value={data.district} label="District" />
        <DisplayItem value={data.primaryContactName} label="Primary Contact" />
        <DisplayItem value={data.primaryContactPhone} label="Primary Phone" />
        <DisplayItem value={data.primaryContactEmail} label="Primary Email" />
      </Space>
    );
  } else return null;
};

const SchoolProfileForm = ({ schoolId, data }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) form.setFieldsValue(data);
  }, [data, form]);

  const submitForm = () => {
    form.validateFields().then(values => {
      dispatch(putSchoolProfile(schoolId, values));
      message.success("School Profile Updated!", 3);
    });
  };

  return (
    <Form form={form} layout="inline" name="school_profile_form">
      <Space direction="vertical">
        <Form.Item name="schoolName" label="School Name">
          <Input />
        </Form.Item>
        <Form.Item name="mascot" label="School Mascot">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="School Address">
          <Input />
        </Form.Item>
        <Form.Item name="city" label="School City">
          <Input />
        </Form.Item>
        <Form.Item name="state" label="School State">
          <Input />
        </Form.Item>
        <Form.Item name="zipCode" label="School Zip">
          <Input />
        </Form.Item>
        <Form.Item name="conference" label="Conference">
          <Input />
        </Form.Item>
        <Form.Item name="division" label="Division">
          <Input />
        </Form.Item>
        <Form.Item name="region" label="Region">
          <Input />
        </Form.Item>
        <Form.Item name="district" label="District">
          <Input />
        </Form.Item>
        <Form.Item name="primaryContactName" label="Primary Contact">
          <Input />
        </Form.Item>
        <Form.Item name="primaryContactPhone" label="Primary Phone">
          <Input />
        </Form.Item>
        <Form.Item name="primaryContactEmail" label="Primary Email">
          <Input />
        </Form.Item>
        <Button type="primary" onClick={submitForm}>
          Update School Profile
        </Button>
      </Space>
    </Form>
  );
};

const SchoolProfile = props => {
  const currentSchoolId = useSelector(selectCurrentSchoolId);
  const coachData = useSelector(selectCoachData);
  const coachDataLoading = useSelector(selectCoachDataLoading);
  const data = useSelector(selectSchoolProfile);
  const loading = useSelector(selectSchoolProfilesLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { schoolId } = parseQueryString(location.search, "schoolId");

  const onChangeSchoolId = schoolId => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, schoolId }),
    );
  };

  useEffect(() => {
    if (!coachData && !coachDataLoading) dispatch(getCoachData());

    if (
      (schoolId && !data && !loading) ||
      (currentSchoolId && schoolId !== currentSchoolId && !loading)
    ) {
      dispatch(getSchoolProfile(schoolId));
    }

    if (!schoolId && coachData) onChangeSchoolId(coachData.schoolId);
  });

  return (
    <div className="">
      <h1>School Profile</h1>
      {coachData && schoolId === coachData.schoolId ? (
        <SchoolProfileForm schoolId={schoolId} data={data} />
      ) : (
        <SchoolProfileView schoolId={schoolId} data={data} />
      )}
    </div>
  );
};

export default SchoolProfile;
