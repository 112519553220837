import React, { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStatesBySport,
  selectStatesLoading,
} from "../redux/selectors/statesSelectors";
import * as queryString from "query-string";
import { useLocation, useNavigate } from "@reach/router";
import { getStatesBySport } from "../redux/actions/statesActions";

const { Option } = Select;

const buildOptionsArray = statesData => {
  return statesData.map(state => (
    <Option value={state.state} key={state.state}>
      {state.state}
    </Option>
  ));
};

export const StateSelect = ({ state, sport }) => {
  const statesData = useSelector(selectStatesBySport(sport));
  const stateOptions = statesData ? buildOptionsArray(statesData) : [];
  const loading = useSelector(selectStatesLoading);
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeState = state => {
    let queryParams = queryString.parse(location.search);
    delete queryParams.rosterId;
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, state }),
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!statesData && !loading) dispatch(getStatesBySport(sport));

    // go to "all" if none is selected:
    if (!state && statesData && statesData.length > 0) {
      onChangeState("ALL");
    }
  });

  return (
    <div>
      <span>Select State: </span>
      <Select value={state} onChange={onChangeState} showArrow={true}>
        <Option value="ALL" key="ALL">
          All
        </Option>
        {stateOptions}
      </Select>
    </div>
  );
};
