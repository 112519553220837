import { Card, Table } from "antd";
import roundToTwo from "../../../utils/roundToTwo";
import { useSelector } from "react-redux";
import {
  selectTeamCapabilitiesDataByRosterId,
  selectTeamCapabilitiesDataLoading,
} from "../../../redux/teamPerformanceCapabilites_slice";

const TeamAnalytics = ({ rosterId }) => {
  useSelector;
  const teamCapabilities = useSelector(
    selectTeamCapabilitiesDataByRosterId(rosterId),
  );

  const teamCapabilitiesLoading = useSelector(
    selectTeamCapabilitiesDataLoading,
  );

  const teamsData = teamCapabilities?.teamAnalytics ?? [];

  console.log("teamCapabilities in teamAnalytics", teamCapabilities);
  const styles = {
    darkBlue: {
      color: "#1155cc",
    },
    black: {
      color: "#000000",
    },
    orange: {
      color: "#f79d16",
    },
    red: {
      color: "#cc0000",
    },
  };

  // change this if you want to modify what data is being shown in each row
  const displayData = [
    {
      fields: [
        { fieldName: "Strength of Schedule", fieldKey: "strengthOfSchedule" },
      ],
    },
    {
      fields: [
        { fieldName: "Team Total Defense", fieldKey: "teamTotalDefense" },
        { fieldName: "Team Pass Defense", fieldKey: "teamPassDefense" },
        { fieldName: "Team Run Defense", fieldKey: "teamRunDefense" },
      ],
    },
    {
      fields: [
        { fieldName: "Team Total Offense", fieldKey: "teamTotalOffense" },
        { fieldName: "Team Pass Offense", fieldKey: "teamPassOffense" },
        { fieldName: "Team Run Offense", fieldKey: "teamRunOffense" },
      ],
    },
  ];

  // not sure how percentages are being calculated in the data at the moment
  // so just going to use this regex to extract the percentages for now
  const formatAnalyticsValue = string => {
    const regex = /\((.*?)%\)/;
    console.log("replacing string with", string.replace(regex, ""));
    const matches = regex.exec(string);
    return matches.length > 0
      ? { value: string.replace(regex, ""), percentage: Number(matches[1]) }
      : null;
  };

  const teamsMapped = teamsData.map((team, index) => {
    return {
      title: team?.teamName,
      dataIndex: team?.teamId,
      width: 300,
      fixed: true,
      render(text, record) {
        // map over each item in the "fields" array in "displayData" and then use the "fieldKey" item to access the same key in "teamsData"
        return (
          <>
            {record?.fields?.map(currentField => {
              const { value, percentage } = formatAnalyticsValue(
                team[currentField?.fieldKey],
              );
              return (
                <div>
                  {value} {renderPercentage(percentage)}
                </div>
              );
            })}
          </>
        );
      },
    };
  });

  const columns = [
    {
      title: "",
      dataIndex: "fields",
      width: 300,
      fixed: true,
      render(text, record) {
        return (
          <>
            {record?.fields?.map(currentField => (
              <div>{currentField?.fieldName}</div>
            ))}
          </>
        );
      },
    },
    ...teamsMapped,
  ];

  const renderPercentage = percentage => {
    if (percentage > 75) {
      return <span style={styles.red}>{`(${percentage}%)`}</span>;
    } else if (percentage > 51) {
      return <span style={styles.orange}>{`(${percentage}%)`}</span>;
    } else if (percentage > 26) {
      return <span style={styles.black}>{`(${percentage}%)`}</span>;
    } else {
      return <span style={styles.darkBlue}>{`(${percentage}%)`}</span>;
    }
  };

  return (
    <Card style={{ marginBottom: "10px" }}>
      <div className="center">
        <h1>Team Analytics</h1>
        <Table
          columns={columns}
          dataSource={displayData}
          pagination={false}
          tableLayout="fixed"
          loading={teamCapabilitiesLoading}
          size="small"
        />
      </div>
    </Card>
  );
};

export default TeamAnalytics;
