import {
  GET_GAMESTATS_FAILURE,
  GET_GAMESTATS_STARTED,
  GET_GAMESTATS_SUCCESS,
} from "../actions/gamestatsActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  currentQueryString: null,
  queryStrings: [],
  dataByQueryString: {},
};

export default function gamestatsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GAMESTATS_STARTED:
      return {
        ...state,
        loading: true,
        currentQueryString: action.payload,
      };
    case GET_GAMESTATS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        queryStrings: state.queryStrings.includes(action.payload.queryString)
          ? state.queryStrings
          : [...state.queryStrings, action.payload.queryString],
        dataByQueryString: {
          ...state.dataByQueryString,
          [action.payload.queryString]: Object.values(action.payload.data),
        },
      };
    case GET_GAMESTATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
