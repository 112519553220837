import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createRecruiterPlayerNote,
  deletePlayersNote,
  getRecruiterPlayerNotes,
} from "../../redux/actions/recruiterPlayerNotesActions";
import { Button, Layout, Modal, Table, Input, Space, Popconfirm } from "antd";
import { EditableCell, sorter } from "../../shared-components/PlayerTable";
import { selectLoading } from "../../redux/selectors/recruiterlistSelectors";
import { formatRelative } from "date-fns";
import { DeleteOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const RecruiterNotes = ({ playerId }) => {
  const dispatch = useDispatch();
  const recruiterPlayerNotesByPlayerId = useSelector(
    store => store.recruiterPlayerNotesData.playerNotesByPlayerId,
  );
  const loading = useSelector(selectLoading);
  const recruiterPlayerNotesForPlayerId =
    recruiterPlayerNotesByPlayerId[playerId];
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);

  const displayData = (recruiterPlayerNotesForPlayerId || []).map(n => {
    return {
      ...n,
      date: new Date(n.date),
      displayDate: formatRelative(new Date(n.date), new Date()),
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "displayDate",
      sorter: sorter("date"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      dataIndex: "listId",
      render: (_, record) => {
        return (
          <Space>
            <Popconfirm
              placement="topRight"
              title={`Are you sure you want to remove this note?`}
              onConfirm={() => dispatch(deletePlayersNote(record.playerNoteId))}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getRecruiterPlayerNotes());
  }, [dispatch]);

  const NewNoteModal = ({ isVisible, onHandle }) => {
    const dispatch = useDispatch();
    const [note, setNote] = useState();

    const okCancel = ok => () => {
      if (ok && note && note.trim() !== "")
        dispatch(
          createRecruiterPlayerNote(
            { playerId: playerId, note: note },
            getRecruiterPlayerNotes,
          ),
        );

      onHandle();
    };

    return (
      <Modal
        title="New Player Note"
        visible={isVisible}
        onOk={okCancel(true)}
        onCancel={okCancel(false)}
        destroyOnClose={true}
      >
        <h3>Note:</h3>
        <TextArea
          rows={4}
          placeholder={"Enter note"}
          onChange={e => setNote(e.target.value)}
          autoFocus
        />
      </Modal>
    );
  };

  return (
    <Layout>
      <Layout.Content style={{ marginLeft: 0, background: "white" }}>
        <Button type={"primary"} onClick={() => setModalVisible(true)}>
          New Note
        </Button>
        <Table
          dataSource={displayData}
          rowKey="playerNoteId"
          columns={columns}
          loading={loading}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Layout.Content>
      <NewNoteModal isVisible={modalVisible} onHandle={hideModal} />
    </Layout>
  );
};

export default RecruiterNotes;
