import { Auth } from "aws-amplify";
import config from "./config";

const conf = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "recruiterAPI",
        endpoint: config.apiGateway.RECRUITER_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "communicationsAPI",
        endpoint: config.apiGateway.COMMUNICATIONS_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "coachAPI",
        endpoint: config.apiGateway.COACH_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "playerAPI",
        endpoint: config.apiGateway.PLAYER_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "usersAPI",
        endpoint: config.apiGateway.USERS_URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "userAdminAPI",
        endpoint: config.apiGateway.USERADMIN_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: "nosqlapi",
        endpoint: "https://gfb0k3hc17.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export default conf;
