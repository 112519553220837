import React from "react";
import image from "../../resources/logo_white_background.jpg";

const Home = props => (
  <div className={"home"}>
    <div
      className={""}
      style={{
        width: "550px",
        height: "300px",
      }}
    >
      <img
        src={image}
        alt={"logo"}
        style={{
          width: "550px",
          height: "300px",
        }}
      />
    </div>
    <p />
    <h1>Hello and welcome to Playmaker!</h1>
    <p>
      You are currently signed in with a share-only profile. You will be able to
      view any profile shared with you from a coach and review that player's
      entire profile.
    </p>
    <p>
      To access all of our features and take full control of your recruiting
      data, request an upgrade and see pricing today by emailing:&nbsp;
      <a href="mailto:Upgrade@playmakerrecruiting.com">
        Upgrade@playmakerrecruiting.com
      </a>
    </p>
    <p>
      Additional features include:
      <ul>
        <li>Evaluation Pipeline</li>
        <li>Advanced Custom Search Tools</li>
        <li>Saved / Shared Players</li>
        <li>Stat Leaders</li>
      </ul>
    </p>
  </div>
);

export default Home;
