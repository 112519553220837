import {
  GET_SCHOOLPROFILE_FAILURE,
  GET_SCHOOLPROFILE_STARTED,
  GET_SCHOOLPROFILE_SUCCESS,
  PUT_SCHOOLPROFILE_FAILURE,
  PUT_SCHOOLPROFILE_STARTED,
  PUT_SCHOOLPROFILE_SUCCESS,
  GET_COACHDATA_FAILURE,
  GET_COACHDATA_STARTED,
  GET_COACHDATA_SUCCESS,
} from "../actions/schoolProfileActions";

const initialState = {
  currentSchoolId: null,
  loading: false,
  syncing: false,
  error: null,
  data: null,
  coachDataLoading: false,
  coachData: null,
};

export default function schoolProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOLPROFILE_STARTED:
      return {
        ...state,
        currentSchoolId: action.payload.schoolId,
        loading: true,
      };
    case GET_SCHOOLPROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
      };
    case GET_SCHOOLPROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case PUT_SCHOOLPROFILE_STARTED:
      return {
        ...state,
        data: action.payload.data,
        syncing: true,
      };
    case PUT_SCHOOLPROFILE_SUCCESS:
      return {
        ...state,
        syncing: false,
        error: null,
      };
    case PUT_SCHOOLPROFILE_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };

    case GET_COACHDATA_STARTED:
      return {
        ...state,
        coachDataLoading: true,
      };
    case GET_COACHDATA_SUCCESS:
      return {
        ...state,
        coachDataLoading: false,
        error: null,
        coachData: action.payload.data,
      };
    case GET_COACHDATA_FAILURE:
      return {
        ...state,
        coachDataLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
