import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Table } from "antd";
import { getMessages } from "../../redux/actions/messageActions";
import { navigate } from "@reach/router";

const sorter = key => (a, b) =>
  typeof a[key] === "string" ? a[key].localeCompare(b[key]) : a[key] - b[key];

const Inbox = props => {
  const dispatch = useDispatch();
  const receivedMessages = useSelector(
    store => store.messageData.receivedMessages,
  );
  const loading = useSelector(store => store.messageData.loading);

  const cellRender = (text, record, index) => {
    if (record.isRead) return <>{text}</>;
    else return <b>{text}</b>;
  };

  const columns = [
    {
      title: "Sent On",
      dataIndex: "sentOn",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("sentOn"),
      render: cellRender,
    },
    {
      title: "From",
      dataIndex: "user_name",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("user_name"),
      render: cellRender,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sortDirections: ["descend", "ascend"],
      sorter: sorter("subject"),
      render: cellRender,
    },
  ];

  useEffect(() => {
    if (!loading && !receivedMessages) {
      dispatch(getMessages());
    }
  });

  return (
    <Layout>
      <h1>Received Messages</h1>
      <Layout.Content style={{ marginRight: 40 }}>
        <Table
          dataSource={receivedMessages}
          rowKey="messageId"
          columns={columns}
          loading={loading}
          //scroll={{ y: 600, x: 4000 }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: 15,
          }}
          size="small"
          onRow={rec => ({
            onDoubleClick: () => {
              navigate("/comm/messages/" + rec.messageId);
            },
          })}
        ></Table>
      </Layout.Content>
    </Layout>
  );
};

export default Inbox;
