import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

const slice = createSlice({
  name: "newuser",
  initialState: {
    syncing: false,
  },
  reducers: {
    newuserSyncing: state => {
      state.syncing = true;
    },
    postNewuserSuccess: state => {
      state.syncing = false;
    },
    postNewuserFailure: (state, error) => {
      state.syncing = false;
      state.error = error;
    },
  },
});
export default slice.reducer;

// Actions
export const { newuserSyncing, postNewuserSuccess, postNewuserFailure } =
  slice.actions;

export const postNewuser = (userId, attribs) => async dispatch => {
  try {
    dispatch(newuserSyncing());
    console.log("signing up", userId, attribs);

    const response = await API.post("usersAPI", `stageUser/${userId}`, {
      body: attribs,
    });

    if (response.error) {
      dispatch(postNewuserFailure(response.error));
    } else {
      dispatch(postNewuserSuccess());
    }
  } catch (e) {
    dispatch(postNewuserFailure(e));
  }
};
