import {
  GET_STATES_FAILURE,
  GET_STATES_STARTED,
  GET_STATES_SUCCESS,
} from "../actions/statesActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  dataBySport: {},
};

export default function statesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dataBySport: {
          ...state.dataBySport,
          [action.payload.sport]: Object.values(action.payload.data),
        },
      };
    case GET_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
