import { Card, Table } from "antd";
import roundToTwo from "../../../utils/roundToTwo";
import { useSelector } from "react-redux";
import {
  selectTeamCapabilitiesDataByRosterId,
  selectTeamCapabilitiesDataLoading,
} from "../../../redux/teamPerformanceCapabilites_slice";

const PlayerAnalytics = ({ rosterId }) => {
  const teamCapabilities = useSelector(
    selectTeamCapabilitiesDataByRosterId(rosterId),
  );

  const teamCapabilitiesLoading = useSelector(
    selectTeamCapabilitiesDataLoading,
  );

  const data = teamCapabilities?.playerAnalytics ?? [];

  const columns = [
    {
      title: "",
      dataIndex: "fieldName",
      width: 300,
      fixed: true,
    },
    {
      title: "Player Contribution",
      dataIndex: "playerContributions",
      width: 300,
      fixed: true,
    },
    {
      title: "Team Total",
      dataIndex: "teamTotal",
      width: 300,
      fixed: true,
    },
  ];
  return (
    <Card style={{ marginBottom: "10px" }}>
      <div className="center">
        <h1>Player Analytics</h1>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          tableLayout="fixed"
          loading={teamCapabilitiesLoading}
          size="small"
        />
      </div>
    </Card>
  );
};

export default PlayerAnalytics;
