import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Layout, Radio, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddToSavedLists from "../../shared-components/addToSavedList";
import PlayerTable from "../../shared-components/PlayerTable";
import {
  selectQuicksearchCurrentQuery,
  selectQuicksearchData,
  selectQuicksearchLoading,
} from "../../redux/selectors/quicksearchSelectors";
import { getQuicksearchByQuerystring } from "../../redux/actions/quicksearchActions";
import { useLocation, useNavigate } from "@reach/router";
import * as queryString from "query-string";

const { Content } = Layout;

const QuickSearch = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const { searchString, coachName, playerName, schoolName } = queryString.parse(
    location.search,
  );
  const query = queryString.stringify({
    searchString,
    coachName,
    playerName,
    schoolName,
  });

  const inferredSearchByType = playerName
    ? "playerName"
    : coachName
      ? "coachName"
      : schoolName
        ? "schoolName"
        : "searchString";
  const [searchByType, setSearchByType] = useState(inferredSearchByType);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const data = useSelector(selectQuicksearchData);
  const currentQuery = useSelector(selectQuicksearchCurrentQuery);
  const loading = useSelector(selectQuicksearchLoading);

  const displayData =
    (searchString || coachName || playerName || schoolName) &&
    !loading &&
    query === currentQuery &&
    data
      ? data
      : [];

  const columns = [
    {
      title: "Player",
      dataIndex: "playerName",
    },
    {
      title: "State",
      dataIndex: "state",
      filterable: true,
    },
    {
      title: "School",
      dataIndex: "schoolName",
    },
    {
      title: "Coaches",
      dataIndex: "coaches",
    },
    {
      title: "Jersey",
      dataIndex: "jersey",
    },
    {
      title: "Position",
      dataIndex: "position",
      filterable: true,
    },
    {
      title: "Graduating Class",
      dataIndex: "graduationYear",
      filterable: true,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    // don't do anything if already loading
    if (
      (searchString || coachName || playerName || schoolName) &&
      !loading &&
      query !== currentQuery
    )
      dispatch(getQuicksearchByQuerystring(query));
  });

  const updateSearchString = searchString => {
    const queryParams = queryString.parse(location.search);
    delete queryParams.playerName;
    delete queryParams.coachName;
    delete queryParams.schoolName;
    delete queryParams.searchString;
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, [searchByType]: searchString }),
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: srk => {
      setSelectedRowKeys(srk);
    },
  };

  return (
    <Layout>
      <Content>
        <h1>Quick Search</h1>
        <Space direction={"vertical"} align={"start"}>
          <Radio.Group onChange={e => setSearchByType(e.target.value)}>
            <Radio value="playerName">Player</Radio>
            <Radio value="coachName">Coach</Radio>
            <Radio value="schoolName">School</Radio>
          </Radio.Group>
          <Input.Search
            placeholder={"Search for players by name, coach, or school"}
            onSearch={updateSearchString}
            enterButton={<SearchOutlined style={{ color: "white" }} />}
            style={{ width: 400 }}
          />

          <AddToSavedLists
            selectedRowKeys={selectedRowKeys}
            clearSelectedRows={() => setSelectedRowKeys([])}
            addedFrom={"quick search"}
            addedFromDetail={searchString}
          />
        </Space>
      </Content>
      <PlayerTable
        rowSelection={rowSelection}
        playerData={displayData}
        columns={columns}
        loading={loading}
        navBase={"/recruiters"}
      />
    </Layout>
  );
};

export default QuickSearch;
