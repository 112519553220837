export const selectSchoolProfilesState = store => store.schoolProfilesData;

export const selectSchoolProfile = store => {
  try {
    return selectSchoolProfilesState(store).data[0];
  } catch (e) {
    return null;
  }
};

export const selectSchoolProfilesLoading = store =>
  selectSchoolProfilesState(store)
    ? selectSchoolProfilesState(store).loading
    : false;

export const selectCurrentSchoolId = store =>
  selectSchoolProfilesState(store)
    ? selectSchoolProfilesState(store).currentSchoolId
    : null;

export const selectCoachData = store => {
  try {
    // user is not a coach:
    if (selectSchoolProfilesState(store).coachData.length === 0) return {};
    else return selectSchoolProfilesState(store).coachData[0];
  } catch (e) {
    return null;
  }
};
export const selectCoachDataLoading = store =>
  selectSchoolProfilesState(store)
    ? selectSchoolProfilesState(store).coachDataLoading
    : false;
