import {
  GET_RECRUITER_LIST_FAILURE,
  GET_RECRUITER_LIST_STARTED,
  GET_RECRUITER_LIST_SUCCESS,
  GET_RECRUITER_LISTS_FAILURE,
  GET_RECRUITER_LISTS_STARTED,
  GET_RECRUITER_LISTS_SUCCESS,
  CREATE_RECRUITER_LIST_FAILURE,
  CREATE_RECRUITER_LIST_STARTED,
  CREATE_RECRUITER_LIST_SUCCESS,
  UPDATE_RECRUITER_LIST_FAILURE,
  UPDATE_RECRUITER_LIST_STARTED,
  UPDATE_RECRUITER_LIST_SUCCESS,
  DELETE_RECRUITER_LIST_FAILURE,
  DELETE_RECRUITER_LIST_STARTED,
  DELETE_RECRUITER_LIST_SUCCESS,
} from "../actions/recruiterlistActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  lists: [],
  allLists: [],
  playerPipelineLists: [],
  gotLists: false,
  listsById: {},
};

export default function recruiterlistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECRUITER_LISTS_STARTED:
      return {
        ...state,
        loading: true,
        gotLists: false,
      };
    case GET_RECRUITER_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lists: action.payload.data
          .filter(list => list.isPlayerPipelineList === 0)
          .sort(
            (a, b) => a.playerPipelineSortOrder - b.playerPipelineSortOrder,
          ),
        allLists: action.payload.data,
        playerPipelineLists: action.payload.data
          .filter(list => list.isPlayerPipelineList === 1)
          .sort(
            (a, b) => a.playerPipelineSortOrder - b.playerPipelineSortOrder,
          ),
        gotLists: true,
      };
    case GET_RECRUITER_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case GET_RECRUITER_LIST_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listsById: {
          ...state.listsById,
          [action.payload.listId]: action.payload.data.map(v => v.playerId),
        },
      };
    case GET_RECRUITER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_RECRUITER_LIST_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_RECRUITER_LIST_SUCCESS:
      const isPlayerPipelineList = action.payload.data.isPlayerPipelineList;
      return {
        ...state,
        loading: false,
        error: null,
        lists:
          isPlayerPipelineList === 0
            ? [
                ...state.lists,
                {
                  listId: action.payload.data.listId,
                  listName: action.payload.listData.listName,
                },
              ]
            : state.lists,
        allLists: [
          ...state.allLists,
          {
            listId: action.payload.data.listId,
            listName: action.payload.listData.listName,
          },
        ],
        playerPipelineLists:
          isPlayerPipelineList === 1
            ? [
                ...state.playerPipelineLists,
                {
                  listId: action.payload.data.listId,
                  listName: action.payload.listData.listName,
                },
              ]
            : state.playerPipelineLists,
        listsById: {
          ...state.listsById,
          [action.payload.data.listId]: action.payload.listData.playerIds,
        },
      };
    case CREATE_RECRUITER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_RECRUITER_LIST_STARTED:
      return {
        ...state,
        loading: true,
        listsById: {
          ...state.listsById,
          [action.payload.listId]: applyAdditionsDeletions(
            state.listsById[action.payload.listId],
            action.payload.listData,
          ),
        },
      };
    case UPDATE_RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_RECRUITER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_RECRUITER_LIST_STARTED:
      const { [action.payload.listId]: dropped, ...restOfListsById } =
        state.listsById;
      return {
        ...state,
        loading: true,
        lists: state.lists.filter(v => v.listId !== action.payload.listId),
        allLists: state.allLists.filter(
          v => v.listId !== action.payload.listId,
        ),
        playerPipelineLists: state.playerPipelineLists.filter(
          v => v.listId !== action.payload.listId,
        ),
        listsById: restOfListsById,
      };
    case DELETE_RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_RECRUITER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

const applyAdditionsDeletions = (arr, { additions = [], deletions = [] }) => {
  if (!arr) return null;
  else return arr.concat(additions).filter(v => deletions.indexOf(v) < 0);
};
