import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

const slice = createSlice({
  name: "customAnalytics",
  initialState: {
    loading: false,
    error: null,
    currentQueryString: null,
    dataByQueryString: {},
  },
  reducers: {
    analyticsLoading: (state, action) => {
      state.loading = true;
      state.currentQueryString = action.payload;
    },
    getAnalyticsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.dataByQueryString = {
        ...state.dataByQueryString,
        [action.payload.queryString]: Object.values(action.payload.data),
      };
    },
    getAnalyticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});
export default slice.reducer;

const cleanupData = data => {
  if (data) {
    return data.map(r => {
      const returnval = { ...r };
      Object.keys(r).forEach(n => {
        if (r[n] === null || r[n] === "") returnval[n] = "NA";
      });

      return returnval;
    });
  }
};

// Actions
export const { analyticsLoading, getAnalyticsSuccess, getAnalyticsFailure } =
  slice.actions;

export const getCustomAnalytics = queryString => async dispatch => {
  try {
    dispatch(analyticsLoading(queryString));
    const data = await API.get(
      "coachAPI",
      `custom-game-analytics?${queryString}`,
    );
    if (data) {
      console.log("got custom analytics for:", queryString);
      dispatch(getAnalyticsSuccess({ queryString, data: cleanupData(data) }));
    }
  } catch (e) {
    console.log("problem getting custom analytics:", e);
    dispatch(getAnalyticsFailure(e));
  }
};

export const selectDataLoading = store => store.customAnalytics.loading;
export const selectDataError = store => store.customAnalytics.error;

export const selectDataByQueryString = queryString => store => {
  return store.customAnalytics.dataByQueryString[queryString];
};
