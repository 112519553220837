import {
  GET_RECRUITER_PLAYER_NOTES_STARTED,
  GET_RECRUITER_PLAYER_NOTES_SUCCESS,
  GET_RECRUITER_PLAYER_NOTES_FAILURE,
  CREATE_RECRUITER_PLAYER_NOTES_STARTED,
  CREATE_RECRUITER_PLAYER_NOTES_SUCCESS,
  CREATE_RECRUITER_PLAYER_NOTES_FAILURE,
  DELETE_RECRUITER_PLAYER_NOTES_STARTED,
  DELETE_RECRUITER_PLAYER_NOTES_SUCCESS,
  DELETE_RECRUITER_PLAYER_NOTES_FAILURE,
} from "../actions/recruiterPlayerNotesActions";

const initialState = {
  loadingPlayerNotes: false,
  error: null,
  playerNotes: [],
  playerNotesByPlayerId: {},
};

export default function recruiterPlayerNotesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case GET_RECRUITER_PLAYER_NOTES_STARTED:
      return {
        ...state,
        loadingPlayerNotes: true,
      };
    case GET_RECRUITER_PLAYER_NOTES_SUCCESS:
      return {
        ...state,
        loadingPlayerNotes: false,
        error: null,
        playerNotes: action.payload.playerNotes,
        playerNotesByPlayerId: getRecruiterPlayerNotesByPlayerId(
          action.payload.playerNotes,
        ),
      };
    case GET_RECRUITER_PLAYER_NOTES_FAILURE:
      return {
        ...state,
        loadingPlayerNotes: false,
        error: action.payload.error,
      };
    case CREATE_RECRUITER_PLAYER_NOTES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_RECRUITER_PLAYER_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CREATE_RECRUITER_PLAYER_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_RECRUITER_PLAYER_NOTES_SUCCESS:
      const updatedNotes = state.playerNotes.filter(
        v => v.playerNoteId !== action.payload.playerNoteId,
      );
      return {
        ...state,
        loadingPlayerNotes: false,
        error: null,
        playerNotes: updatedNotes,
        playerNotesByPlayerId: getRecruiterPlayerNotesByPlayerId(updatedNotes),
      };
    case DELETE_RECRUITER_PLAYER_NOTES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case DELETE_RECRUITER_PLAYER_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

function getRecruiterPlayerNotesByPlayerId(playerNotes) {
  return playerNotes.reduce((r, a) => {
    r[a.playerId] = r[a.playerId] || [];
    r[a.playerId].push({ ...a });
    return r;
  }, Object.create(null));
}
