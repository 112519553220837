import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { convertInches } from "./utils";

const slice = createSlice({
  name: "referrals",
  initialState: {
    loading: false,
    syncing: false,
    error: null,
    referrals: null,
  },
  reducers: {
    referralsLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    referralsSyncing: (state, action) => {
      state.syncing = action.payload;
      state.error = null;
    },
    getReferralsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.referrals = action.payload.data;
    },
  },
});
export default slice.reducer;

// Actions
export const { referralsLoading, referralsSyncing, getReferralsSuccess } =
  slice.actions;

export const getRecruiterReferrals = () => async dispatch => {
  try {
    console.log("getting referrals");
    dispatch(referralsLoading(true));
    const response = await API.get("recruiterAPI", `referrals`);
    dispatch(getReferralsSuccess({ data: response }));
  } catch (e) {
    dispatch(referralsLoading(false));
    console.log(e.message);
  }
};

export const getPlayerReferrals = () => async dispatch => {
    try {
        dispatch(referralsLoading(true));
        const response = await API.get('playerAPI', `referrals`);
        dispatch(getReferralsSuccess({data: response}));
    } catch (e) {
        dispatch(referralsLoading(false));
        console.log(e.message);
    }
}

export const getCoachReferrals = (rosterId) => async dispatch => {
    try {
        console.log('getting referrals');
        dispatch(referralsLoading(true));
        const response = await API.get('coachAPI', `referrals/${rosterId}`);
        dispatch(getReferralsSuccess({data: response}));
    } catch (e) {
        dispatch(referralsLoading(false));
        console.log(e.message);
    }
}

export const updateReferral = playerId => async dispatch => {
  try {
    console.log("updating referral", playerId);
    dispatch(referralsSyncing(true));
    await API.put("playerAPI", `referrals/${playerId}`, {});
    dispatch(referralsSyncing(false));
  } catch (e) {
    dispatch(referralsSyncing(false));
    console.log(e.message);
  }
};

export const selectReferrals = store =>
  store.referralData && store.referralData.referrals
    ? store.referralData.referrals.map(p => {
        return {
          ...p,
          key: p.playerId,
          displayHeight: convertInches(p.height),
        };
      })
    : null;

export const selectReferralsLoading = store =>
  store.referralData ? store.referralData.loading : false;
