import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import {
  selectGamesByRosterId,
  selectGamesLoading,
} from "../../../redux/selectors/gamesSelectors";
import {
  getGamesByRosterId,
  createGame,
  deleteGame,
  updateGame,
} from "../../../redux/actions/gamesActions";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { SchoolTeamSelect } from "../../../shared-components/schoolTeamSelect";
import { Link } from "@reach/router";
import momentGenerateConfig from "antd/node_modules/rc-picker/lib/generate/moment";

const MyDatePicker = ({ value, ...otherProps }) => {
  const dateFormat = "MM/DD/YYYY";

  const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig);
  return (
    <MomentDatePicker
      value={value ? moment.utc(value, dateFormat) : null}
      format={dateFormat}
      {...otherProps}
    />
  );
};
const LocationSelect = props => {
  return (
    <Select placeholder="Select Location" allowClear {...props}>
      <Select.Option value="home">home</Select.Option>
      <Select.Option value="away">away</Select.Option>
      <Select.Option value="neutral">neutral</Select.Option>
    </Select>
  );
};

const GameTypeSelect = props => {
  return (
    <Select placeholder="Select Game Type" allowClear {...props}>
      <Select.Option value="scrimmage">Scrimmage</Select.Option>
      <Select.Option value="district">District</Select.Option>
      <Select.Option value="nondistrict">Non-District</Select.Option>
      <Select.Option value="playoff">Playoff</Select.Option>
    </Select>
  );
};

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = <Input />;
  if (dataIndex === "displayDate")
    inputNode = <MyDatePicker value={record.date} />;
  else if (dataIndex === "gameType") inputNode = <GameTypeSelect />;
  else if (dataIndex === "location") inputNode = <LocationSelect />;
  else if (dataIndex === "opponent")
    inputNode = <SchoolTeamSelect level={"Varsity"} record={record} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditSchedule = ({ rosterId, seasonId }) => {
  const gameData = useSelector(selectGamesByRosterId(rosterId));
  const gameDataLoading = useSelector(selectGamesLoading);

  const [addingRow, setAddingRow] = useState(false);

  let displayData = gameData
    ? gameData
        .map(r => {
          return {
            ...r,
            key: r.gameId,
            displayDate: moment.utc(new Date(r.date)).format("M/D/YYYY"),
            opponent: r.opposingSchoolName + " " + r.opposingTeamName,
          };
        })
        .concat(
          !addingRow
            ? []
            : [{ key: "newRow", displayDate: null, opponent: [] }],
        )
    : addingRow
      ? [{ key: "newRow", displayDate: null, opponent: [] }]
      : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (rosterId && !gameData && !gameDataLoading) {
      dispatch(getGamesByRosterId(rosterId));
    }
  });

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const addGame = () => {
    setAddingRow(true);
    setEditingKey("newRow");
    form.setFieldsValue({
      key: "newRow",
      displayDate: null,
      opponent: [],
      gameType: null,
      location: null,
    });
  };

  const saveRow = async record => {
    try {
      let newData = await form.validateFields();
      if (moment.isMoment(newData.displayDate))
        newData.date = newData.displayDate.format("YYYY-MM-DD");

      let opponent = newData.opponent;
      try {
        opponent = JSON.parse(newData.opponent);
      } catch (e) {
        // noop
      }

      if (typeof opponent === "object")
        newData = {
          ...newData,
          opposingTeamId: opponent.teamId,
          opposingTeamName: opponent.teamName,
          opposingSchoolId: opponent.schoolId,
          opposingSchoolName: opponent.schoolName,
        };

      console.log("updating game", { ...record, ...newData });

      if (record.key === "newRow") {
        dispatch(
          createGame({ ...record, ...newData, rosterId, seasonId }, rosterId),
        );
        setAddingRow(false);
      } else
        dispatch(
          updateGame(record.gameId, { ...record, ...newData }, rosterId),
        );

      setEditingKey("");
      message.success("Game updated!", 2);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "displayDate",
      editable: true,
    },
    {
      title: "Opponent",
      dataIndex: "opponent",
      editable: true,
      render: (_, r) => (
        <Link to={`../games/${rosterId}/${r.gameId}`}>{r.opponent}</Link>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      editable: true,
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      editable: true,
    },
    {
      dataIndex: "operation",
      render: (_, record) => {
        if (isEditing(record)) {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => saveRow(record)}
              />
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => {
                  setEditingKey("");
                  setAddingRow(false);
                }}
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
              <Popconfirm
                placement="top"
                title={() => (
                  <>
                    <p>Are you sure you want to delete this game?</p>
                    <p>
                      This will also remove play-by-play data for this game{" "}
                      <br />
                      and calculated player, team, and score summaries.
                    </p>
                  </>
                )}
                onConfirm={() => dispatch(deleteGame(record.gameId, rosterId))}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          );
        }
      },
    },
  ].map(column => {
    return !column.editable
      ? column
      : {
          ...column,
          onCell: record => ({
            record,
            title: column.title,
            dataIndex: column.dataIndex,
            editing: isEditing(record),
          }),
        };
  });

  return (
    <div style={{}}>
      <h1>Edit Schedule</h1>
      <Form form={form} component={false}>
        <Table
          rowKey="gameId"
          columns={columns}
          dataSource={displayData}
          pagination={false}
          tableLayout="fixed"
          loading={gameDataLoading}
          style={{ width: "100%" }}
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      <Button
        type="dashed"
        onClick={() => {
          addGame();
        }}
        block
      >
        <PlusOutlined /> Add Game
      </Button>
    </div>
  );
};

export default EditSchedule;
