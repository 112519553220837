import { convertInches } from "../utils";

export const selectPlayerstatsState = store => store.playerstatsData;

export const selectPlayerstatsByPlayerId = playerId => store =>
  selectPlayerstatsState(store) &&
  selectPlayerstatsState(store).dataByPlayerId[playerId]
    ? {
        ...selectPlayerstatsState(store).dataByPlayerId[playerId],
        non_game_stats: selectPlayerstatsState(store).dataByPlayerId[
          playerId
        ].non_game_stats.map(r => {
          return {
            ...r,
            displayHeight: convertInches(r.height),
          };
        }),
      }
    : null;

export const selectPlayerstatsNGFBByPlayerId = playerId => store =>
  selectPlayerstatsState(store)
    ? selectPlayerstatsState(store).ngfbDataByPlayerId[playerId]
    : null;

export const selectPlayerstatsLoading = store =>
  selectPlayerstatsState(store) ? selectPlayerstatsState(store).loading : false;
