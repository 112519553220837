import {
  GET_DEPTH_CHART_PLAYERS_FAILURE,
  GET_DEPTH_CHART_PLAYERS_STARTED,
  GET_DEPTH_CHART_PLAYERS_SUCCESS,
  PUT_DEPTH_CHART_PLAYERS_FAILURE,
  PUT_DEPTH_CHART_PLAYERS_STARTED,
  PUT_DEPTH_CHART_PLAYERS_SUCCESS,
  CREATE_DEPTH_CHART_PLAYERS_SUCCESS,
  CREATE_DEPTH_CHART_PLAYERS_STARTED,
  CREATE_DEPTH_CHART_PLAYERS_FAILURE,
  UPDATE_DEPTH_CHART_PLAYERS_BY_POSITION,
  RESET_DEPTH_CHART_PLAYERS_BY_POSITION,
} from "../actions/depthChartActions";
import { COLOR_BY_POSITION_TYPE } from "../../shared-components/categories_fb";

const initialState = {
  loadingPlayers: false,
  error: null,
  depthChartPlayerData: null,
  depthChartActiveChartId: null,
  depthChartPlayerPositionsByLevel: null,
  initialdepthChartPlayerPositionsByLevel: null,
  depthChartPlayerDataByLevelAndPosition: null,
  initialDepthChartPlayerDataByLevelAndPosition: null,
};

export default function depthChartReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPTH_CHART_PLAYERS_STARTED:
      return {
        ...state,
        loadingPlayers: true,
      };
    case GET_DEPTH_CHART_PLAYERS_SUCCESS:
      return {
        ...state,
        loadingPlayers: false,
        error: null,
        depthChartPlayerData: action.payload.depthChartPlayers,
        depthChartActiveChartId:
          action.payload.depthChartPlayers &&
          action.payload.depthChartPlayers.length > 0
            ? action.payload.depthChartPlayers[0].chartId
            : "noChartId",
        depthChartPlayerPositionsByLevel: getPlayersPositionsByLevel(
          action.payload.depthChartPlayers,
        ),
        initialdepthChartPlayerPositionsByLevel: getPlayersPositionsByLevel(
          action.payload.depthChartPlayers,
        ),
        depthChartPlayerDataByLevelAndPosition: getPlayersByPosition(
          action.payload.depthChartPlayers,
        ),
        initialDepthChartPlayerDataByLevelAndPosition: getPlayersByPosition(
          action.payload.depthChartPlayers,
        ),
      };
    case GET_DEPTH_CHART_PLAYERS_FAILURE:
      return {
        ...state,
        loadingPlayers: false,
        error: action.payload.error,
      };
    case PUT_DEPTH_CHART_PLAYERS_STARTED: {
      return {
        ...state,
        depthChartPlayerData: (!state.depthChartPlayerData
          ? []
          : state.depthChartPlayerData.filter(
              v =>
                !action.payload.depthChartPlayers
                  .map(dcp => dcp.playerId)
                  .includes(v.playerId),
            )
        ).concat(action.payload.depthChartPlayers),
      };
    }
    case PUT_DEPTH_CHART_PLAYERS_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case PUT_DEPTH_CHART_PLAYERS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case CREATE_DEPTH_CHART_PLAYERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DEPTH_CHART_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CREATE_DEPTH_CHART_PLAYERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_DEPTH_CHART_PLAYERS_BY_POSITION:
      let newDepthChartPlayerDataByLevelAndPosition = {
        ...state.depthChartPlayerDataByLevelAndPosition,
      };
      let newdepthChartPlayerPositionsByLevel = {
        ...state.depthChartPlayerPositionsByLevel,
      };
      if (action.payload.type === "updatePosition") {
        newDepthChartPlayerDataByLevelAndPosition[action.payload.level][
          action.payload.newPostionName
        ] = action.payload.depthChartPlayers;
        delete newDepthChartPlayerDataByLevelAndPosition[action.payload.level][
          action.payload.position
        ];
        const oldPositionIndex = newdepthChartPlayerPositionsByLevel[
          action.payload.level
        ].indexOf(action.payload.position);
        newdepthChartPlayerPositionsByLevel[action.payload.level].splice(
          oldPositionIndex,
          1,
          action.payload.newPostionName,
        );
      } else if (action.payload.type === "updatePlayerDepth") {
        newDepthChartPlayerDataByLevelAndPosition[action.payload.level][
          action.payload.position
        ] = action.payload.depthChartPlayers.map(player => {
          return {
            ...player,
            positionTypeColor: getPositionTypeColorForPlayer(player),
          };
        });
      } else if (action.payload.type === "removePosition") {
        delete newDepthChartPlayerDataByLevelAndPosition[action.payload.level][
          action.payload.position
        ];
        const oldPositionIndex = newdepthChartPlayerPositionsByLevel[
          action.payload.level
        ].indexOf(action.payload.position);
        newdepthChartPlayerPositionsByLevel[action.payload.level].splice(
          oldPositionIndex,
          1,
        );
      } else if (action.payload.type === "addPosition") {
        if (!newDepthChartPlayerDataByLevelAndPosition[action.payload.level]) {
          newDepthChartPlayerDataByLevelAndPosition[action.payload.level] = [];
          newdepthChartPlayerPositionsByLevel[action.payload.level] = [];
        }
        newDepthChartPlayerDataByLevelAndPosition[action.payload.level][
          action.payload.position
        ] = [];
        newdepthChartPlayerPositionsByLevel[action.payload.level].push(
          action.payload.position,
        );
      }
      return {
        ...state,
        depthChartPlayerDataByLevelAndPosition:
          newDepthChartPlayerDataByLevelAndPosition,
        depthChartPlayerPositionsByLevel: newdepthChartPlayerPositionsByLevel,
      };
    case RESET_DEPTH_CHART_PLAYERS_BY_POSITION:
      return {
        ...state,
        depthChartPlayerDataByLevelAndPosition: {
          ...state.initialDepthChartPlayerDataByLevelAndPosition,
        },
        depthChartPlayerPositionsByLevel: {
          ...state.initialdepthChartPlayerPositionsByLevel,
        },
      };
    default:
      return state;
  }
}

function getPlayersPositionsByLevel(depthChartPlayers) {
  return depthChartPlayers
    .sort((a, b) => a.order - b.order)
    .reduce((r, a) => {
      r[a.level] = r[a.level] || [];
      if (r[a.level].indexOf(a.position) === -1) {
        r[a.level].push(a.position);
      }
      return r;
    }, Object.create(null));
}

function getPlayersByPosition(depthChartPlayers) {
  return depthChartPlayers.reduce((r, a) => {
    a.positionTypeColor = getPositionTypeColorForPlayer(a);
    r[a.level] = r[a.level] || [];
    r[a.level][a.position] = r[a.level][a.position] || [];
    r[a.level][a.position].push({ ...a });
    r[a.level][a.position].sort((a, b) => a.depth - b.depth);
    return r;
  }, Object.create(null));
}

function getPositionTypeColorForPlayer(player) {
  let positionColor;
  COLOR_BY_POSITION_TYPE.forEach(positionType => {
    if (positionType.positions.includes(player.rosterPosition)) {
      positionColor = positionType.color;
    }
  });

  return positionColor;
}
