import React, { useEffect } from "react";
import { Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeamGameoffeffByGameId,
  selectTeamstatsLoading,
} from "../../../redux/selectors/teamstatsSelectors";
import { getTeamGameOffEff } from "../../../redux/actions/teamstatsActions";

export const TeamEfficiency = ({ gameId, od }) => {
  const teamStats = useSelector(selectTeamGameoffeffByGameId(gameId, od));
  const teamStatsLoading = useSelector(selectTeamstatsLoading);
  const haveData = teamStats && teamStats[0];

  const dataToTableMapping = [
    { key: "row1", rowName: "Run/Pass", data: "runPass" },
    { key: "row2", rowName: "Pass Comp %", data: "passComp" },
    { key: "row3", rowName: "Run Efficiency", data: "runEff" },
    { key: "row4", rowName: "Conversion %", data: "conversionPercent" },
    { key: "row5", rowName: "Turnovers", data: "turnovers" },
    { key: "row6", rowName: "Negative Plays", data: "negatives" },
  ];

  const transformData = data => {
    const enhancedData = data.map(r => {
      return {
        ...r,
        runPass:
          (r.runs ? "" + r.runs : "0") + "/" + (r.passes ? "" + r.passes : "0"),
        passComp: r.passes
          ? (r.pass_comps / r.passes).toLocaleString("en-US", {
              style: "percent",
            })
          : "--",
        runEff: r.runs
          ? (r.runs_over3 / r.runs).toLocaleString("en-US", {
              style: "percent",
            })
          : "--",
        conversionPercent:
          r.passes + r.runs
            ? (r.conversions / (r.passes + r.runs)).toLocaleString("en-US", {
                style: "percent",
              })
            : "--",
      };
    });

    return dataToTableMapping.map(r => {
      let newRow = { ...r };
      enhancedData.forEach(d => (newRow["down" + d.down] = d[r.data]));

      return newRow;
    });
  };

  const displayData = haveData ? transformData(teamStats) : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (gameId && !teamStatsLoading && !teamStats) {
      dispatch(getTeamGameOffEff(gameId));
    }
  });

  const columns = [
    { title: "", dataIndex: "rowName" },
    { title: "1st Down", dataIndex: "down1" },
    { title: "2nd Down", dataIndex: "down2" },
    { title: "3rd Down", dataIndex: "down3" },
    { title: "4th Down", dataIndex: "down4" },
  ];

  return (
    <Card>
      <h1>{od === "D" ? "Defensive" : "Offensive"} Efficiency</h1>
      <Table
        columns={columns}
        dataSource={displayData}
        pagination={false}
        tableLayout="fixed"
        loading={teamStatsLoading}
        size="small"
      />
    </Card>
  );
};
