import { API } from "aws-amplify";

export const GET_SCHOOLPROFILE_STARTED = "GET_SCHOOLPROFILE_STARTED";
export const GET_SCHOOLPROFILE_SUCCESS = "GET_SCHOOLPROFILE_SUCCESS";
export const GET_SCHOOLPROFILE_FAILURE = "GET_SCHOOLPROFILE_FAILURE";

export const PUT_SCHOOLPROFILE_STARTED = "PUT_SCHOOLPROFILE_STARTED";
export const PUT_SCHOOLPROFILE_SUCCESS = "PUT_SCHOOLPROFILE_SUCCESS";
export const PUT_SCHOOLPROFILE_FAILURE = "PUT_SCHOOLPROFILE_FAILURE";

export const GET_COACHDATA_STARTED = "GET_COACHDATA_STARTED";
export const GET_COACHDATA_SUCCESS = "GET_COACHDATA_SUCCESS";
export const GET_COACHDATA_FAILURE = "GET_COACHDATA_FAILURE";

export function getSchoolProfile(schoolId) {
  console.log("getting schoolprofile");

  return async dispatch => {
    try {
      dispatch(getSchoolProfileStarted(schoolId));
      const school = await API.get("coachAPI", `schoolprofile/${schoolId}`);
      console.log("got schoolprofile");
      dispatch(getSchoolProfileSuccess(school));
    } catch (e) {
      console.log("problem getting schoolprofile:", e);
      dispatch(getSchoolProfileFailure(e));
    }
  };
}

const getSchoolProfileSuccess = (data = []) => ({
  type: GET_SCHOOLPROFILE_SUCCESS,
  payload: { data },
});

const getSchoolProfileStarted = schoolId => ({
  type: GET_SCHOOLPROFILE_STARTED,
  payload: { schoolId },
});

const getSchoolProfileFailure = error => ({
  type: GET_SCHOOLPROFILE_FAILURE,
  payload: {
    error,
  },
});

export function putSchoolProfile(schoolId, profileData) {
  console.log("putting schoolprofile");

  return async dispatch => {
    try {
      dispatch(putSchoolProfileStarted(profileData));
      await API.put("coachAPI", `schoolprofile/${schoolId}`, {
        body: profileData,
      });
      console.log("put schoolprofile");
      dispatch(putSchoolProfileSuccess());
    } catch (e) {
      console.log("problem putting schoolprofile:", e);
      dispatch(putSchoolProfileFailure(e));
    }
  };
}

const putSchoolProfileSuccess = () => ({
  type: PUT_SCHOOLPROFILE_SUCCESS,
});

const putSchoolProfileStarted = data => ({
  type: PUT_SCHOOLPROFILE_STARTED,
  payload: { data },
});

const putSchoolProfileFailure = error => ({
  type: PUT_SCHOOLPROFILE_FAILURE,
  payload: {
    error,
  },
});

export function getCoachData() {
  console.log("getting coachdata");

  return async dispatch => {
    try {
      dispatch(getCoachDataStarted());
      const coach = await API.get("coachAPI", `coachdata`);
      console.log("got coachdata");
      dispatch(getCoachDataSuccess(coach));
    } catch (e) {
      console.log("problem getting coachdata:", e);
      dispatch(getCoachDataFailure(e));
    }
  };
}

const getCoachDataSuccess = (data = []) => ({
  type: GET_COACHDATA_SUCCESS,
  payload: { data },
});

const getCoachDataStarted = () => ({
  type: GET_COACHDATA_STARTED,
});

const getCoachDataFailure = error => ({
  type: GET_COACHDATA_FAILURE,
  payload: {
    error,
  },
});
