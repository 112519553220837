import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRosterId,
  selectRosterPlayersByRosterId,
} from "../../../redux/selectors/rosterSelectors";
import { getRosterPlayersByRosterId } from "../../../redux/actions/rosterActions";
import { getUser } from "../../../redux/user_slice";
import { postNongamestats } from "../../../redux/actions/nongamestatsActions";
import { format } from "date-fns";
import FlatfileWrapper from "../../../shared-components/FlatfileWrapper";

const ngsUploadBlueprint = {
  name: "Non-game Stats Data",
  slug: "ngs-data",
  readonly: false,
  allowAdditionalFields: true,
  fields: [
    {
      label: "Player Name",
      key: "playerName",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    {
      label: "Player Jersey",
      key: "jersey",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    { label: "Position", key: "position", type: "string" },
    { label: "Graduation Year", key: "graduationYear", type: "string" },
    {
      label: "Forty-yard Dash (seconds)",
      key: "forty_yd_dash",
      type: "string",
    },
    { label: "Bench Press (lbs)", key: "bench_press", type: "string" },
    { label: "Squat (lbs)", key: "squat", type: "string" },
    { label: "Vertical (inches)", key: "vertical", type: "string" },
    { label: "Power Clean (lbs)", key: "power_clean", type: "string" },
    { label: "Broad Jump (inches)", key: "broad_jump", type: "string" },
    { label: "Pro Agility (seconds)", key: "pro_agility", type: "string" },
    {
      label: "Sixty-yard Shuttle (seconds)",
      key: "sixty_yd_shuttle",
      type: "string",
    },
    {
      label: "Twenty-yard Shuttle (seconds)",
      key: "twenty_yd_shuttle",
      type: "string",
    },
    {
      label: "Three Cone Drill (seconds)",
      key: "three_cone_drill",
      type: "string",
    },
    { label: "Height (inches)", key: "height", type: "string" },
    { label: "weight (lbs)", key: "weight", type: "string" },
    { label: "Wingspan (inches)", key: "wingspan", type: "string" },
  ],
};

const FlatfileNGSUpload = ({ rosterId }) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userAttributes) {
      dispatch(getUser());
    }
  });

  const currentRosterIdInStore = useSelector(selectCurrentRosterId);
  const rosterPlayerData = useSelector(selectRosterPlayersByRosterId(rosterId));
  const loadingPlayers = useSelector(store => store.rosterData.loadingPlayers);

  useEffect(() => {
    if (!!rosterId && rosterId !== currentRosterIdInStore && !loadingPlayers) {
      dispatch(getRosterPlayersByRosterId(rosterId));
    }
  });

  const transformData = data => {
    return data
      .map(record => {
        const player = rosterPlayerData.find(
          p => p.jersey === String(record.jersey),
        );

        return {
          ...record,
          playerId: player ? player.playerId : null,
        };
      })
      .map(record => {
        if (!record.date_recorded)
          return {
            ...record,
            date_recorded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          };
        else return record;
      });
  };

  return (
    <FlatfileWrapper
      blueprint={ngsUploadBlueprint}
      handleRecord={record => {
        const j = record.get("jersey");
        if (
          rosterPlayerData &&
          j &&
          !rosterPlayerData.find(p => p.jersey === j)
        )
          record.addError("jersey", "Jersey not found in roster");
      }}
      handleSubmit={data =>
        dispatch(postNongamestats(rosterId, transformData(data)))
      }
      buttonContent={<>Upload Non-game Stats</>}
    />
  );
};

export default FlatfileNGSUpload;
