export const selectGamesState = store => store.gamesData;

export const selectGamesByRosterId = rosterId => store =>
  selectGamesState(store)
    ? selectGamesState(store).gamesByRosterId[rosterId]
    : null;

export const selectGotGamesList = store =>
  selectGamesState(store) ? selectGamesState(store).gotGameList : false;

export const selectGamesLoading = store =>
  selectGamesState(store) ? selectGamesState(store).gamesLoading : false;

export const selectGamedataByGameId = gameId => store =>
  selectGamesState(store)
    ? selectGamesState(store).gamedataByGameId[gameId]
    : null;

export const selectGamedataLoading = store =>
  selectGamesState(store) ? selectGamesState(store).gamedataLoading : false;
