import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Table } from "antd";
import { selectLoading } from "../../redux/selectors/recruiterlistSelectors";
import { formatRelative } from "date-fns";
import { getRecruiterPlayerPipelineHistory } from "../../redux/actions/recruiterPlayerPipelineHistoryActions";
import { EditableCell, sorter } from "../../shared-components/PlayerTable";

const RecruiterNotes = ({ playerId }) => {
  const dispatch = useDispatch();
  const recruiterPlayerPipelineHistoryByPlayerId = useSelector(
    store =>
      store.recruiterPlayerPipelineHistoryData.playerPipelineHistoryByPlayerId,
  );
  const loading = useSelector(selectLoading);
  const recruiterPlayerPipelineHistoryForPlayerId =
    recruiterPlayerPipelineHistoryByPlayerId[playerId];

  const displayData = (recruiterPlayerPipelineHistoryForPlayerId || []).map(
    n => {
      return {
        ...n,
        date: new Date(n.dateAdded),
        displayDate: formatRelative(new Date(n.dateAdded), new Date()),
        rowKey: `${n.listId}-${n.playerId}`,
      };
    },
  );

  useEffect(() => {
    dispatch(getRecruiterPlayerPipelineHistory());
  }, [dispatch]);

  const columns = [
    {
      title: "Date Added",
      dataIndex: "displayDate",
      sorter: sorter("date"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "List Name",
      dataIndex: "listName",
    },
    {
      title: "Added From",
      dataIndex: "addedFrom",
    },
  ];

  return (
    <Layout>
      <Layout.Content style={{ marginLeft: 0 }}>
        <Table
          dataSource={displayData}
          rowKey="rowKey"
          columns={columns}
          loading={loading}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default RecruiterNotes;
