export const selectDepthChartState = store => store.depthChartData;

export const selectDepthChartPlayers = store =>
  selectDepthChartState(store)
    ? selectDepthChartState(store).depthChartPlayerData
    : null;

export const selectDepthChartPlayersByPosition = (level, position) => store =>
  selectDepthChartPlayers(store)
    ? selectDepthChartState(store).depthChartPlayerDataByLevelAndPosition[
        level
      ][position].sort((a, b) => a.depth - b.depth)
    : null;

export const selectDepthChartPlayerPositionsByLevel = () => store =>
  selectDepthChartPlayers(store)
    ? selectDepthChartState(store).depthChartPlayerPositionsByLevel
    : null;

export const selectDepthChartPlayersGroupedByPosition = store =>
  selectDepthChartPlayers(store)
    ? selectDepthChartState(store).depthChartPlayerDataByLevelAndPosition
    : {};

export const selectActiveDepthByChartId = store =>
  selectDepthChartPlayers(store)
    ? selectDepthChartState(store).depthChartActiveChartId
    : "";

export const selectDepthChartLoading = store =>
  selectDepthChartState(store)
    ? selectDepthChartState(store).loadingPlayers
    : false;
