import { API } from "aws-amplify";

export const GET_MESSAGE_COUNTS_STARTED = "GET_MESSAGE_COUNTS_STARTED";
export const GET_MESSAGE_COUNTS_SUCCESS = "GET_MESSAGE_COUNTS_SUCCESS";
export const GET_MESSAGE_COUNTS_FAILURE = "GET_MESSAGE_COUNTS_FAILURE";

export const GET_MESSAGES_STARTED = "GET_MESSAGES_STARTED";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const GET_SENT_MESSAGES_STARTED = "GET_SENT_MESSAGES_STARTED";
export const GET_SENT_MESSAGES_SUCCESS = "GET_SENT_MESSAGES_SUCCESS";
export const GET_SENT_MESSAGES_FAILURE = "GET_SENT_MESSAGES_FAILURE";

export const GET_MESSAGE_STARTED = "GET_MESSAGE_STARTED";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";

export const CREATE_MESSAGE_STARTED = "CREATE_MESSAGE_STARTED";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE";

export const UPDATE_MESSAGE_STARTED = "UPDATE_MESSAGE_STARTED";
export const UPDATE_MESSAGE_SUCCESS = "UPDATE_MESSAGE_SUCCESS";
export const UPDATE_MESSAGE_FAILURE = "UPDATE_MESSAGE_FAILURE";

export const GET_USER_STARTED = "GET_USER_STARTED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export function getMessageCounts() {
  console.log("getting message counts");
  return async dispatch => {
    try {
      dispatch(getMessageCountsStarted());
      const messageCounts = await API.get(
        "communicationsAPI",
        `messages/messageCounts`,
      );
      dispatch(getMessageCountsSuccess(messageCounts));
    } catch (e) {
      dispatch(getMessageCountsFailure(e));
    }
  };
}

const getMessageCountsSuccess = (data = []) => ({
  type: GET_MESSAGE_COUNTS_SUCCESS,
  payload: { data },
});

const getMessageCountsStarted = () => ({
  type: GET_MESSAGE_COUNTS_STARTED,
});

const getMessageCountsFailure = error => ({
  type: GET_MESSAGE_COUNTS_FAILURE,
  payload: { error },
});

export function getMessages(isRead = null) {
  console.log("getting messages");
  return async dispatch => {
    try {
      dispatch(getMessagesStarted());
      const messages = await API.get(
        "communicationsAPI",
        `messages/${isRead !== null ? "?isRead=" + isRead : ""}`,
      );
      dispatch(getMessagesSuccess(messages));
    } catch (e) {
      dispatch(getMessagesFailure(e));
    }
  };
}

const getMessagesSuccess = (data = []) => ({
  type: GET_MESSAGES_SUCCESS,
  payload: { data },
});

const getMessagesStarted = () => ({
  type: GET_MESSAGES_STARTED,
});

const getMessagesFailure = error => ({
  type: GET_MESSAGES_FAILURE,
  payload: { error },
});

export function getSentMessages() {
  console.log("getting sent messages");
  return async dispatch => {
    try {
      dispatch(getSentMessagesStarted());
      const sentMessages = await API.get(
        "communicationsAPI",
        `messages/sentMessages`,
      );
      dispatch(getSentMessagesSuccess(sentMessages));
    } catch (e) {
      dispatch(getSentMessagesFailure(e));
    }
  };
}

const getSentMessagesSuccess = (data = []) => ({
  type: GET_SENT_MESSAGES_SUCCESS,
  payload: { data },
});

const getSentMessagesStarted = () => ({
  type: GET_SENT_MESSAGES_STARTED,
});

const getSentMessagesFailure = error => ({
  type: GET_SENT_MESSAGES_FAILURE,
  payload: { error },
});

export function getMessageById(messageId) {
  console.log("getting message:", messageId);
  return async dispatch => {
    try {
      dispatch(getMessageByIdStarted());
      const message = await API.get(
        "communicationsAPI",
        `messages/${messageId}`,
      );
      dispatch(getMessageByIdSuccess(messageId, message));
    } catch (e) {
      dispatch(getMessageByIdFailure(e));
    }
  };
}

const getMessageByIdSuccess = (messageId, data = {}) => ({
  type: GET_MESSAGE_SUCCESS,
  payload: { messageId, data },
});

const getMessageByIdStarted = () => ({
  type: GET_MESSAGE_STARTED,
});

const getMessageByIdFailure = error => ({
  type: GET_MESSAGE_FAILURE,
  payload: { error },
});

export function createMessage(messageData) {
  // expects messageData = {toId, previousMessageId, subject, content}
  // api returns { messageId: ... }
  console.log("creating message", messageData);
  return async dispatch => {
    try {
      dispatch(createMessageStarted());
      const message = await API.post("communicationsAPI", `messages/`, {
        body: messageData,
      });
      dispatch(createMessageSuccess(message));
    } catch (e) {
      dispatch(createMessageFailure(e));
    }
  };
}

const createMessageSuccess = messageData => ({
  type: CREATE_MESSAGE_SUCCESS,
  payload: { messageData },
});

const createMessageStarted = () => ({
  type: CREATE_MESSAGE_STARTED,
});

const createMessageFailure = error => ({
  type: CREATE_MESSAGE_FAILURE,
  payload: { error },
});

export function updateMessage(messageId, isRead = true, isArchived = false) {
  console.log("updating message", messageId);
  return async dispatch => {
    try {
      dispatch(updateMessageStarted(messageId, isRead, isArchived));
      await API.put(
        "communicationsAPI",
        `messages/${messageId}?isRead=${isRead}&isArchived=${isArchived}`,
      );
      dispatch(updateMessageSuccess());
    } catch (e) {
      dispatch(updateMessageFailure(e));
    }
  };
}

const updateMessageSuccess = () => ({
  type: UPDATE_MESSAGE_SUCCESS,
});

const updateMessageStarted = (messageId, isRead, isArchived) => ({
  type: UPDATE_MESSAGE_STARTED,
  payload: { messageId, isRead, isArchived },
});

const updateMessageFailure = error => ({
  type: UPDATE_MESSAGE_FAILURE,
  payload: { error },
});

export function getUserById(id) {
  console.log("getting user:", id);
  return async dispatch => {
    try {
      dispatch(getUserByIdStarted());
      const user = await API.get("communicationsAPI", `users/${id}`);
      dispatch(getUserByIdSuccess(id, user));
    } catch (e) {
      dispatch(getUserByIdFailure(e));
    }
  };
}

const getUserByIdSuccess = (id, data = {}) => ({
  type: GET_USER_SUCCESS,
  payload: { id, data },
});

const getUserByIdStarted = () => ({
  type: GET_USER_STARTED,
});

const getUserByIdFailure = error => ({
  type: GET_USER_FAILURE,
  payload: { error },
});
