import React, { useEffect, useState } from "react";
import { Input, Modal, Tabs } from "antd";
import {
  createScoutingTeam,
  getRosterTeams,
} from "../../../redux/actions/rosterActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "@reach/router";
import * as queryString from "query-string";
import { SeasonsSelect } from "../../../shared-components/seasonsSelect";
import { parseQueryString } from "../../../shared-components/queryStringUtils";

const { TabPane } = Tabs;

const NewScoutingRoster = ({ isVisible, onHandle, seasonId, sport }) => {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState();

  const okCancel = ok => () => {
    if (ok && teamName && teamName.trim() !== "")
      dispatch(createScoutingTeam(teamName, seasonId, sport));

    setTeamName(null);
    onHandle();
  };

  return (
    <Modal
      title="New Scouting Team"
      visible={isVisible}
      onOk={okCancel(true)}
      onCancel={okCancel(false)}
      destroyOnClose={true}
    >
      <h3>Team Name:</h3>
      <Input
        placeholder={"Team Name"}
        onChange={e => setTeamName(e.target.value)}
        autoFocus
        onPressEnter={okCancel(true)}
      />
    </Modal>
  );
};

const RosterTabs = ({
  rosterId,
  showAll,
  hideInactive,
  hideScouting,
  hideRosters,
  children,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);

  const { seasonId } = parseQueryString(location.search);
  const rosterTeamData = useSelector(
    store => store.rosterData.rosterTeamDataBySeasonId[seasonId],
  );
  const loadingTeams = useSelector(store => store.rosterData.loadingTeams);
  //const teamsLoaded = useSelector(store => store.rosterData.teamsLoaded);

  const onChangeRoster = (rosterId, replace) => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname +
        "?" +
        queryString.stringify({ ...queryParams, rosterId }),
      { replace },
    );
  };

  let rosterOptions = rosterTeamData
    ? rosterTeamData
        .filter(r => !hideInactive || r.level !== "Inactive")
        .filter(r => !hideScouting || r.level !== "Scouting")
        .map(r => {
          return {
            ...r,
            tabName:
              r.level === "Scouting" ? r.level + ": " + r.teamName : r.level,
          };
        })
        .map(r => (
          <TabPane
            key={r.rosterId}
            value={r.rosterId}
            tab={r.tabName}
            closable={false}
          >
            {children}
          </TabPane>
        ))
    : [];

  if (showAll)
    rosterOptions.unshift(<TabPane key="all" value="all" tab="All" />);

  useEffect(() => {
    if (!rosterTeamData && !loadingTeams) {
      dispatch(getRosterTeams(seasonId));
    }

    // go to first team if none is selected:
    if (!rosterId && rosterTeamData && rosterTeamData.length > 0) {
      onChangeRoster(showAll ? "all" : rosterTeamData[0].rosterId, true);
    }
  });

  return (
    <div
      style={{
        display: "flex",
        direction: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginRight: 30,
      }}
    >
      {!hideRosters ? (
        <Tabs
          activeKey={rosterId}
          onChange={onChangeRoster}
          size={"large"}
          //type="editable-card"
          //onEdit={() => setModalVisible(true)}
        >
          {rosterOptions}
        </Tabs>
      ) : (
        <></>
      )}
      <SeasonsSelect
        sport={"fb"}
        seasonId={seasonId}
        selectable={!hideRosters}
      />
      <NewScoutingRoster
        isVisible={modalVisible}
        onHandle={hideModal}
        seasonId={seasonId}
        sport={"fb"}
      />
    </div>
  );
};

export default RosterTabs;
