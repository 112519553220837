import React, { useState } from "react";
import { Card, Divider, message, Modal, Popconfirm, Space } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { selectDepthChartPlayersByPosition } from "../../../redux/selectors/depthChartSelectors";
import { updateDepthChartPlayerDataByPosition } from "../../../redux/actions/depthChartActions";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";
import { Button } from "antd";
import { selectRosterPlayersByRosterId } from "../../../redux/selectors/rosterSelectors";
import { PlayerSelect } from "../../../shared-components/PlayerSelect";

export const PositionCardsForPosition = ({
  chartId,
  level,
  position,
  readOnly = false,
  rosterId,
}) => {
  let playersByPosition = useSelector(
    selectDepthChartPlayersByPosition(level, position),
  );
  const playersOnRoster = useSelector(selectRosterPlayersByRosterId(rosterId));
  const dispatch = useDispatch();
  const positionInput = React.createRef();
  const [addPlayerModalVisible, setAddPlayerModalVisible] = useState(false);
  const hideAddPlayerModal = () => setAddPlayerModalVisible(false);

  const SortableItem = SortableElement(({ value }) => (
    <span>
      {value["playerName"]} ({value["graduationYear"]}) &nbsp;{" "}
      {value["isIneligibile"] ? <WarningOutlined /> : null}
    </span>
  ));

  const SortableList = SortableContainer(({ items, readOnly = false }) => {
    return (
      <ul className="sortableList">
        {items.map((value, index) =>
          !readOnly ? (
            <p key={index} style={{ backgroundColor: value.positionTypeColor }}>
              <Popconfirm
                placement="topRight"
                title={`Are you sure you want to remove ${value["playerName"]}?`}
                onConfirm={() => removePlayerFromPosition(value)}
                okText="Yes"
                cancelText="No"
              >
                <CloseCircleOutlined />
              </Popconfirm>
              &nbsp;&nbsp;
              <SortableItem key={`item-${index}`} index={index} value={value} />
            </p>
          ) : (
            <p
              key={`item-${index}`}
              style={{ backgroundColor: value.positionTypeColor }}
            >
              {value["playerName"]} ({value["graduationYear"]}) &nbsp;{" "}
              {value["isIneligibile"] ? <WarningOutlined /> : null}
            </p>
          ),
        )}
      </ul>
    );
  });

  function onSortChange(items) {
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        const playerIndex = playersByPosition.findIndex(
          player => item.playerId === player.playerId,
        );
        if (playerIndex !== -1) {
          playersByPosition[playerIndex].depth = index + 1;
        }
      });

      dispatch(
        updateDepthChartPlayerDataByPosition(
          "updatePlayerDepth",
          level,
          position,
          playersByPosition,
        ),
      );
    }
  }

  function onPositionNameChange() {
    const newPostionName = positionInput.current.value;
    playersByPosition.map(player => (player.position = newPostionName));

    if (newPostionName !== position) {
      dispatch(
        updateDepthChartPlayerDataByPosition(
          "updatePosition",
          level,
          position,
          playersByPosition,
          newPostionName,
        ),
      );
      message.success(`Position name has been updated.`, 2);
    }
  }

  function onSortEnd({ oldIndex, newIndex }) {
    playersByPosition = arrayMove(playersByPosition, oldIndex, newIndex);
    onSortChange(playersByPosition);
  }

  const removePositionFromLevel = () => {
    dispatch(
      updateDepthChartPlayerDataByPosition("removePosition", level, position),
    );
    message.success(`Position has been removed.`, 2);
  };

  const removePlayerFromPosition = player => {
    const oldPlayerIndex = playersByPosition.indexOf(player);
    playersByPosition.splice(oldPlayerIndex, 1);

    dispatch(
      updateDepthChartPlayerDataByPosition(
        "updatePlayerDepth",
        level,
        position,
        playersByPosition,
      ),
    );
    message.success(`Player has been removed.`, 2);
  };

  const addSelectedPlayer = idForSelectedPlayer => {
    if (
      idForSelectedPlayer &&
      !playersByPosition.find(player => player.playerId === idForSelectedPlayer)
    ) {
      const player = playersOnRoster.find(
        player => player.playerId === idForSelectedPlayer,
      );
      const newPlayer = {
        playerId: idForSelectedPlayer,
        rosterId: rosterId,
        chartId: chartId,
        playerName: player.playerName,
        level: level,
        position: position,
        depth: 0,
        isIneligibile: player.isIneligibile,
        rosterPosition: player.position,
        graduationYear: player.graduationYear,
      };

      playersByPosition.push(newPlayer);

      dispatch(
        updateDepthChartPlayerDataByPosition(
          "updatePlayerDepth",
          level,
          position,
          playersByPosition,
        ),
      );
      message.success(`Player has been added.`, 2);
    }
  };

  const SelectPlayerToAdd = ({ isVisible, onHandle }) => {
    let selectedPlayer;
    const okCancel = ok => () => {
      if (ok && selectedPlayer !== undefined) addSelectedPlayer(selectedPlayer);

      onHandle();
    };

    const onPlayerSelect = playerId => {
      selectedPlayer = playerId;
    };

    return (
      <Modal
        title="Select player for position"
        visible={isVisible}
        onOk={okCancel(true)}
        onCancel={okCancel(false)}
        destroyOnClose={true}
      >
        <PlayerSelect
          onChange={onPlayerSelect}
          rosterId={rosterId}
          width={200}
        />
      </Modal>
    );
  };

  return playersByPosition ? (
    <div style={{ margin: "10px", borderStyle: "ridge", background: "#fff" }}>
      <Card
        key={position}
        title={
          !readOnly ? (
            <input
              ref={positionInput}
              defaultValue={position}
              placeholder="Position Name"
              onBlur={() => onPositionNameChange()}
            />
          ) : (
            position
          )
        }
        size="small"
        bordered={false}
      >
        <SortableList
          items={playersByPosition}
          onSortEnd={onSortEnd}
          readOnly={readOnly}
        />
        {!readOnly ? (
          <>
            <Divider />
            <Space>
              <Popconfirm
                placement="topRight"
                title={`Are you sure you want to remove this position?`}
                onConfirm={() => removePositionFromLevel()}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<CloseCircleOutlined />}>Remove Position</Button>
              </Popconfirm>
              <Button
                icon={<UserAddOutlined />}
                onClick={() => setAddPlayerModalVisible(true)}
              >
                Add Player
              </Button>
            </Space>
          </>
        ) : null}
      </Card>
      <SelectPlayerToAdd
        isVisible={addPlayerModalVisible}
        onHandle={hideAddPlayerModal}
      />
    </div>
  ) : null;
};
