import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerAboutsByPlayerId,
  selectPlayerLinksByPlayerId,
  selectPlayerProfileLoading,
} from "../../redux/selectors/playerProfileSelectors";
import {
  getMyPlayerIds,
  getPlayerAboutsByPlayerId,
  getPlayerLinksByPlayerId,
} from "../../redux/actions/playerProfileActions";
import { Button, Layout, Spin, Descriptions } from "antd";
import { getPlayerstatsByPlayerId } from "../../redux/actions/playerstatsActions";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { useNavigate } from "@reach/router";

const PlayerViewProfile = ({ playerId }) => {
  const navigate = useNavigate();
  const abouts = useSelector(selectPlayerAboutsByPlayerId(playerId));
  const links = useSelector(selectPlayerLinksByPlayerId(playerId));
  const playerStatsData = useSelector(selectPlayerstatsByPlayerId(playerId));
  const playerStatsDataLoading = useSelector(
    store => store.playerstatsData.loading,
  );

  //   const myPlayerIds = useSelector(selectMyPlayerIds);
  const loading = useSelector(selectPlayerProfileLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!loading && !(abouts && links)) {
    dispatch(getPlayerAboutsByPlayerId(playerId));
    dispatch(getPlayerLinksByPlayerId(playerId));
    dispatch(getMyPlayerIds());
    // }
  }, [playerId]);

  useEffect(() => {
    if (playerId) {
      dispatch(getPlayerstatsByPlayerId(playerId));
    }
  }, [playerId]);

  const styles = {
    descriptionItem: {
      paddingBottom: 24,
    },
  };

  const ViewProfileForm = (
    <>
      <Descriptions>
        <Descriptions.Item
          label="Name"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.playerName}
        </Descriptions.Item>
        <Descriptions.Item
          label="E-mail"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.email}
        </Descriptions.Item>
        <Descriptions.Item
          label="Phone"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.phone}
        </Descriptions.Item>
        {abouts && (
          <Descriptions.Item
            label="About"
            span={24}
            style={styles.descriptionItem}>
            {abouts[0]?.content}
          </Descriptions.Item>
        )}
        {links?.length > 0 &&
          links
            ?.filter(currentLink => currentLink?.linkType.includes("link"))
            ?.map((link, i) => (
              <Descriptions.Item
                label={`Link ${i + 1}`}
                span={24}
                style={styles.descriptionItem}>
                {link?.link}
              </Descriptions.Item>
            ))}
      </Descriptions>
      <Button
        loading={loading || playerStatsDataLoading}
        type="primary"
        onClick={() => {
          navigate("/players/profile/edit");
        }}>
        Edit
      </Button>
    </>
  );

  return (
    <Layout>
      <Layout.Content style={{ paddingBottom: "48px" }}>
        <h1>Profile</h1>
        {loading || playerStatsDataLoading ? <Spin /> : ViewProfileForm}
      </Layout.Content>
    </Layout>
  );
};

export default PlayerViewProfile;
