import React from "react";
import { Select } from "antd";

const { Option } = Select;

export const GraduatingClassSelect = ({
  onChange,
  width,
  defaultValue,
  value,
}) => {
  const start = 2018;
  const end = new Date().getFullYear() + 8;
  const yearRange = [...Array(end - start + 1).keys()].map(x => x + start);

  const options = yearRange.map(year => (
    <Option key={year} values={year}>
      {year}
    </Option>
  ));

  return (
    <Select
      showSearch={true}
      placeholder="Select a Year"
      style={!!width ? { width: width } : {}}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
    >
      {options}
    </Select>
  );
};
