import { API } from "aws-amplify";

export const GET_PLAYERSTATS_STARTED = "GET_PLAYERSTATS_STARTED";
export const GET_PLAYERSTATS_SUCCESS = "GET_PLAYERSTATS_SUCCESS";
export const GET_PLAYERSTATS_FAILURE = "GET_PLAYERSTATS_FAILURE";

export const GET_PLAYERSTATS_NGFB_STARTED = "GET_PLAYERSTATS_NGFB_STARTED";
export const GET_PLAYERSTATS_NGFB_SUCCESS = "GET_PLAYERSTATS_NGFB_SUCCESS";
export const GET_PLAYERSTATS_NGFB_FAILURE = "GET_PLAYERSTATS_NGFB_FAILURE";

export const RESET_PLAYERSTATS = "RESET_PLAYERSTATS";

export function getPlayerstatsByPlayerId(playerId) {
  console.log("getting playerstats for: ", playerId);

  return async dispatch => {
    try {
      dispatch(getPlayerstatsStarted(playerId));
      const playerInfo = await API.get(
        "playerAPI",
        `all-player-info/${playerId}`,
      );
      console.log("got playerstats for:", playerId);
      return dispatch(getPlayerstatsSuccess(playerId, playerInfo));
    } catch (e) {
      console.log("problem getting playerstats:", e);
      return dispatch(getPlayerstatsFailure(e));
    }
  };
}

const getPlayerstatsSuccess = (playerId, data = []) => ({
  type: GET_PLAYERSTATS_SUCCESS,
  payload: { playerId, data },
});

const getPlayerstatsStarted = playerId => ({
  type: GET_PLAYERSTATS_STARTED,
  payload: playerId,
});

const getPlayerstatsFailure = error => ({
  type: GET_PLAYERSTATS_FAILURE,
  payload: {
    error,
  },
});

export function getPlayerstatsNGFBByPlayerId(playerId) {
  console.log("getting playerstatsNGFB for: ", playerId);

  return async dispatch => {
    try {
      dispatch(getPlayerstatsNGFBStarted(playerId));
      const gameStats = await API.get(
        "playerAPI",
        `non-game-stats-fb/${playerId}`,
      );
      console.log("got playerstats for:", playerId);
      return dispatch(getPlayerstatsNGFBSuccess(playerId, gameStats));
    } catch (e) {
      console.log("problem getting playerstats:", e);
      return dispatch(getPlayerstatsNGFBFailure(e));
    }
  };
}

const getPlayerstatsNGFBSuccess = (playerId, data = []) => ({
  type: GET_PLAYERSTATS_NGFB_SUCCESS,
  payload: { playerId, data },
});

const getPlayerstatsNGFBStarted = playerId => ({
  type: GET_PLAYERSTATS_NGFB_STARTED,
  payload: playerId,
});

const getPlayerstatsNGFBFailure = error => ({
  type: GET_PLAYERSTATS_NGFB_FAILURE,
  payload: {
    error,
  },
});

export function resetPlayerstats() {
  return {
    type: RESET_PLAYERSTATS,
  };
}
