import {
  GET_ROSTER_PLAYERS_FAILURE,
  GET_ROSTER_PLAYERS_STARTED,
  GET_ROSTER_PLAYERS_SUCCESS,
  GET_ROSTER_TEAMS_FAILURE,
  GET_ROSTER_TEAMS_STARTED,
  GET_ROSTER_TEAMS_SUCCESS,
  PUT_ROSTER_PLAYERS_FAILURE,
  PUT_ROSTER_PLAYERS_STARTED,
  PUT_ROSTER_PLAYERS_SUCCESS,
  ADD_ROSTER_PLAYERS_FAILURE,
  ADD_ROSTER_PLAYERS_STARTED,
  ADD_ROSTER_PLAYERS_SUCCESS,
  UPDATE_ROSTER_PLAYERS_BY_POSITION,
  RESET_ROSTER_PLAYERS_BY_POSITION,
  RESET_ROSTER_PLAYERS,
} from "../actions/rosterActions";

const initialState = {
  loadingPlayers: false,
  loadingTeams: false,
  teamsLoaded: false,
  syncing: false,
  error: null,
  currentRosterId: null,
  currentSeasonId: null,
  playerIds: [],
  rosterPlayerData: null,
  rosterPlayerDataByPosition: null,
  initialRosterPlayerDataByPosition: null,
  rosterTeamDataBySeasonId: {},
};

export default function rosterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROSTER_PLAYERS_STARTED:
      return {
        ...state,
        loadingPlayers: true,
        currentSeasonId: null,
        currentRosterId: null,
      };
    case GET_ROSTER_PLAYERS_SUCCESS:
      return {
        ...state,
        loadingPlayers: false,
        error: null,
        currentRosterId: action.payload.rosterId,
        currentSeasonId: action.payload.seasonId,
        playerIds: state.playerIds.concat(
          action.payload.rosterPlayers
            .map(v => v.playerIds)
            .filter(p => state.playerIds.indexOf(p) < 0),
        ),
        rosterPlayerData: action.payload.rosterPlayers,
        rosterPlayerDataByPosition: getPlayersByPosition(
          action.payload.rosterPlayers,
        ),
        initialRosterPlayerDataByPosition: getPlayersByPosition(
          action.payload.rosterPlayers,
        ),
      };
    case GET_ROSTER_PLAYERS_FAILURE:
      return {
        ...state,
        loadingPlayers: false,
        error: action.payload.error,
      };
    case GET_ROSTER_TEAMS_STARTED:
      return {
        ...state,
        loadingTeams: true,
      };
    case GET_ROSTER_TEAMS_SUCCESS:
      return {
        ...state,
        loadingTeams: false,
        teamsLoaded: true,
        error: null,
        rosterTeamDataBySeasonId: {
          ...state.rosterTeamDataBySeasonId,
          [action.payload.seasonId]: action.payload.teams.filter(
            team => team.level !== "Scouting",
          ),
        },
      };
    case GET_ROSTER_TEAMS_FAILURE:
      return {
        ...state,
        loadingTeams: false,
        teamsLoaded: true,
        error: action.payload.error,
      };
    case PUT_ROSTER_PLAYERS_STARTED: {
      return {
        ...state,
        rosterPlayerData: (!state.rosterPlayerData
          ? []
          : state.rosterPlayerData.filter(
              v =>
                !action.payload.rosterPlayers
                  .map(rp => rp.playerId)
                  .includes(v.playerId),
            )
        ).concat(action.payload.rosterPlayers),
        syncing: true,
      };
    }
    case PUT_ROSTER_PLAYERS_SUCCESS:
      return {
        ...state,
        syncing: false,
        error: null,
      };
    case PUT_ROSTER_PLAYERS_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };
    case ADD_ROSTER_PLAYERS_STARTED: {
      return {
        ...state,
        syncing: true,
      };
    }
    case ADD_ROSTER_PLAYERS_SUCCESS: {
      const newRosterPlayerData = state.rosterPlayerData.concat(
        action.payload.rosterPlayers,
      );
      return {
        ...state,
        syncing: false,
        error: null,
        playerIds: state.playerIds.concat(
          action.payload.rosterPlayers.map(v => v.playerIds),
        ),
        rosterPlayerData: newRosterPlayerData,
        rosterPlayerDataByPosition: getPlayersByPosition(newRosterPlayerData),
        initialRosterPlayerDataByPosition:
          getPlayersByPosition(newRosterPlayerData),
      };
    }
    case ADD_ROSTER_PLAYERS_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };
    case UPDATE_ROSTER_PLAYERS_BY_POSITION:
      let newRosterPlayerDataByPosition = {
        ...state.rosterPlayerDataByPosition,
      };
      newRosterPlayerDataByPosition[action.payload.rosterId][
        action.payload.position
      ] = action.payload.rosterPlayers;
      return {
        ...state,
        rosterPlayerDataByPosition: newRosterPlayerDataByPosition,
      };
    case RESET_ROSTER_PLAYERS_BY_POSITION:
      return {
        ...state,
        rosterPlayerDataByPosition: {
          ...state.initialRosterPlayerDataByPosition,
        },
      };
    case RESET_ROSTER_PLAYERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

function getPlayersByPosition(rosterPlayers) {
  return rosterPlayers.reduce((r, a) => {
    r[a.rosterId] = r[a.rosterId] || [];
    r[a.rosterId][a.position] = r[a.rosterId][a.position] || [];
    r[a.rosterId][a.position].push({ ...a });
    r[a.rosterId][a.position].sort((a, b) => a.depth - b.depth);
    return r;
  }, Object.create(null));
}
