import { Select } from "antd";
import React from "react";
import statGroupings from "./categories_fb";
import "./custom_styles.css";

export const SeasonStatisticSelect = props => {
  return (
    <Select
      placeholder="Select stat"
      allowClear
      style={{ width: 800, height: 900 }}
      {...props}
    >
      {Object.keys(statGroupings).map(group => (
        <Select.OptGroup
          key={statGroupings[group].name}
          label={statGroupings[group].name}
        >
          {statGroupings[group].statLabels.map(s => (
            <Select.Option key={s.stat} value={s.stat}>
              {s.label}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};
