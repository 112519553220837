import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/user_slice";

const User = props => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }
  }, [dispatch, userData.userId]);

  return (
    <div>
      <h1>User Management</h1>
      <pre>{JSON.stringify(userData, null, 4)}</pre>
    </div>
  );
};

export default User;
