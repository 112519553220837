import * as queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomAnalytics,
  selectDataByQueryString,
  selectDataLoading,
} from "../../../../redux/customanaytics_slice";
import { analyticStats, enhanceData } from "./utils";
import React, { useEffect } from "react";
import { Card, Table } from "antd";

const CustomAnalyticsTable = ({
  gameIds,
  odk,
  groupingField1,
  groupingField2,
  filterValue,
  extraFilterField,
  extraFilterValue,
  statName,
}) => {
  const qs = queryString.stringify(
    {
      gameIds,
      filterValue,
      extraFilterField,
      extraFilterValue,
      groupingField1,
      groupingField2,
      odk,
    },
    { arrayFormat: "comma" },
  );
  const data = useSelector(selectDataByQueryString(qs));
  const dataLoading = useSelector(selectDataLoading);

  const dispatch = useDispatch();

  const gf2 = groupingField2 === "gameId" ? "game" : groupingField2;
  const enhancedData = enhanceData(data, groupingField1, filterValue, gf2);

  const columns = data
    ? [{ title: "", dataIndex: "rowName" }].concat(
        enhancedData
          .map(r => r[gf2])
          .filter((v, i, s) => s.indexOf(v) === i)
          .map((v, i) => {
            return { title: v, dataIndex: "col" + i };
          }),
      )
    : null;

  const transformData = data => {
    if (groupingField1 === "all_stats" || filterValue !== "all") {
      return analyticStats
        .filter(stat => stat.all_stats)
        .map(s => {
          const newrow = { key: s.value, rowName: s.name };

          columns.forEach(c => {
            try {
              newrow[c.dataIndex] = enhancedData.find(r => r[gf2] === c.title)[
                s.value
              ];
            } catch (e) {}
          });

          return newrow;
        });
    } else {
      return data
        .map(r => r[groupingField1])
        .filter((v, i, s) => s.indexOf(v) === i)
        .map(v => {
          let newrow = { key: v, rowName: v };
          if (groupingField1 === "gameId") {
            newrow.rowName = enhancedData.find(
              r => r[groupingField1] === v,
            ).game;
          }

          columns.forEach((c, i) => {
            try {
              newrow[c.dataIndex] = enhancedData.find(
                r => r[groupingField1] === v && r[gf2] === c.title,
              )[statName];
            } catch (e) {}
          });

          return newrow;
        });
    }
  };

  const displayData = data ? transformData(data) : null;

  useEffect(() => {
    if (gameIds && !dataLoading && !data) {
      dispatch(getCustomAnalytics(qs));
    }
  });

  return (
    <Card>
      <Table
        columns={columns}
        dataSource={displayData}
        pagination={false}
        tableLayout="fixed"
        loading={dataLoading}
        size="small"
      />
    </Card>
  );
};

export default CustomAnalyticsTable;
