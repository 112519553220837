import React from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import { createMessage } from "../redux/actions/messageActions";

const NewMessage = ({
  toId,
  previousMessageId = null,
  subject = null,
  closeCallback,
}) => {
  const dispatch = useDispatch();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 10,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      //span: 16,
    },
  };

  const onFinish = values => {
    dispatch(
      createMessage({
        subject: values.Subject ? values.Subject : subject,
        content: values.Message,
        toId,
        previousMessageId,
      }),
    );
    closeCallback();
  };

  const onFinishFailed = errorInfo => {};

  return (
    <Form
      {...layout}
      name="NewMessage"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Subject"
        name="Subject"
        rules={[
          {
            required: subject === null,
            whitespace: true,
            max: 128,
          },
        ]}
      >
        <Input defaultValue={subject} />
      </Form.Item>

      <Form.Item
        label="Message"
        name="Message"
        rules={[
          {
            required: true,
            whitespace: true,
            max: 256,
          },
        ]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Send
        </Button>
        <Button onClick={closeCallback}>Cancel</Button>
      </Form.Item>
    </Form>
  );
};

export default NewMessage;
