import React from "react";
import image from "../../resources/logo_white_background.jpg";

const Home = props => (
  <div className={"home"}>
    <div
      className={""}
      style={{
        width: "550px",
        height: "300px",
      }}
    >
      <img
        src={image}
        alt={"logo"}
        style={{
          width: "550px",
          height: "300px",
        }}
      />
    </div>
    <p />
    <h1>Hello and welcome to Playmaker!</h1>
    <p>
      Our Platform is designed to help bridge the information gap in the
      recruiting process and provide in-depth performance data and physical
      attributes in a searchable database. Playmaker also includes general
      recruiting process management tools to keep track of recruits as new data
      is added. Our goal is to provide a greater universe of players for schools
      to recruit from and provide greater detail to allow recruiters to make
      more informed decisions to save time and money.
    </p>
    <p>
      We are currently in the process of finishing certain functionalities,
      however we are looking for any and all feedback. This includes desired
      features, information formatting, and overall experience improvements. We
      want to make the platform as user friendly as possible, and the only way
      that happens is with your help.
    </p>
    <p>
      Where to Start - We suggest you start in the Research section. Here you
      will see 3 areas labeled advanced search, quick search, and saved
      searches. In Advanced Search you can create a search based on many
      criteria. We suggest exploring these search capabilities and how
      information is then presented. Please be aware there is not an extremely
      large amount of data to search through, so we recommend not adding too
      many filters.
    </p>
    <p>
      You can then select players in the search results you would like to save
      for later and add them to a saved list by typing the name of an existing
      list, or naming a new one in the text box at the top of the table. You can
      then create new searches and revisit old ones in the saved searches
      section.
    </p>
    <p>
      We then suggest checking out the quick search section where you can search
      by name, school, or coach. Feel free to experiment with this, as it is
      intended to provide a way to search for a player that you came across
      through other channels.
    </p>
    <p>
      We then suggest exploring the saved lists you created and review player
      details. You will be able to see player game by game stats, season totals,
      and non-game measurables. You will also find an example saved list already
      there with players to review. These profiles will at a future date also
      include links to player social media handles, selected highlights, and
      game film for review.
    </p>
    <p>
      You may also note that there is a messaging and notes section when looking
      at a specific player and clicking on their name. These sections are place
      holders but will be where you and other recruiting staff can add notes
      about the player and information from conversations. The messaging section
      will also pull all communication from that player specifically. We are
      open to any content suggestions for these pages as well.
    </p>
    <p>
      Thank you for your time and feedback, and we look forward to working with
      you as we move forward.
    </p>
  </div>
);

export default Home;
