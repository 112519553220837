import {
  GET_NONGAMESTATS_FAILURE,
  GET_NONGAMESTATS_STARTED,
  GET_NONGAMESTATS_SUCCESS,
  PUT_NONGAMESTATS_FAILURE,
  PUT_NONGAMESTATS_STARTED,
  PUT_NONGAMESTATS_SUCCESS,
  POST_NONGAMESTATS_FAILURE,
  POST_NONGAMESTATS_STARTED,
  POST_NONGAMESTATS_SUCCESS,
} from "../actions/nongamestatsActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  dataByRosterStat: {},
};

export default function nongamestatsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NONGAMESTATS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_NONGAMESTATS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dataByRosterStat: {
          ...state.dataByRosterStat,
          [action.payload.rosterId + "~" + action.payload.stat]:
            action.payload.data,
        },
      };
    case GET_NONGAMESTATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PUT_NONGAMESTATS_STARTED: {
      const newDataByRosterStat = { ...state.dataByRosterStat };
      const stats =
        newDataByRosterStat[
          action.payload.rosterId + "~" + action.payload.stat
        ];
      stats.find(p => p.playerId === action.payload.playerId)[
        action.payload.targetStat
      ] = action.payload.target;
      return {
        ...state,
        syncing: true,
        dataByRosterStat: newDataByRosterStat,
      };
    }
    case PUT_NONGAMESTATS_SUCCESS:
      return {
        ...state,
        syncing: false,
        error: null,
      };
    case PUT_NONGAMESTATS_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };
    case POST_NONGAMESTATS_STARTED: {
      return {
        ...state,
        syncing: true,
        dataByRosterStat: null,
      };
    }
    case POST_NONGAMESTATS_SUCCESS:
      return {
        ...state,
        syncing: false,
        error: null,
      };
    case POST_NONGAMESTATS_FAILURE:
      return {
        ...state,
        syncing: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
