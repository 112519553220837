import {
  GET_RECRUITER_SEARCH_FAILURE,
  GET_RECRUITER_SEARCH_STARTED,
  GET_RECRUITER_SEARCH_SUCCESS,
  GET_RECRUITER_SEARCHES_FAILURE,
  GET_RECRUITER_SEARCHES_STARTED,
  GET_RECRUITER_SEARCHES_SUCCESS,
  CREATE_RECRUITER_SEARCH_FAILURE,
  CREATE_RECRUITER_SEARCH_STARTED,
  CREATE_RECRUITER_SEARCH_SUCCESS,
  UPDATE_RECRUITER_SEARCH_FAILURE,
  UPDATE_RECRUITER_SEARCH_STARTED,
  UPDATE_RECRUITER_SEARCH_SUCCESS,
  DELETE_RECRUITER_SEARCH_FAILURE,
  DELETE_RECRUITER_SEARCH_STARTED,
  DELETE_RECRUITER_SEARCH_SUCCESS,
  CLEAR_NEW_SEARCHID,
} from "../actions/recruitersearchActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  searches: [],
  gotSearches: false,
  searchesById: {},
  newSearchId: null,
};

export default function recruiterSearchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECRUITER_SEARCHES_STARTED:
      return {
        ...state,
        loading: true,
        gotSearches: false,
      };
    case GET_RECRUITER_SEARCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        searches: action.payload.data,
        gotSearches: true,
      };
    case GET_RECRUITER_SEARCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case GET_RECRUITER_SEARCH_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_RECRUITER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        searchesById: {
          ...state.searchesById,
          [action.payload.searchId]: action.payload.data,
        },
      };
    case GET_RECRUITER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_RECRUITER_SEARCH_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_RECRUITER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        searches: [
          ...state.searches,
          {
            searchId: action.payload.data.searchId,
            searchName: action.payload.searchData.searchName,
          },
        ],
        searchesById: {
          ...state.searchesById,
          [action.payload.data.searchId]: action.payload.searchData.criteria,
        },
        newSearchId: action.payload.data.searchId,
      };
    case CREATE_RECRUITER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_NEW_SEARCHID:
      return {
        ...state,
        newSearchId: null,
      };

    case UPDATE_RECRUITER_SEARCH_STARTED:
      return {
        ...state,
        loading: true,
        searchesById: {
          ...state.searchesById,
          [action.payload.searchId]: { ...action.payload.searchData },
        },
      };
    case UPDATE_RECRUITER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_RECRUITER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DELETE_RECRUITER_SEARCH_STARTED:
      const { [action.payload.searchId]: dropped, ...restOfSearchesById } =
        state.searchesById;
      return {
        ...state,
        loading: true,
        searches: state.searches.filter(
          v => v.searchId !== action.payload.searchId,
        ),
        searchesById: restOfSearchesById,
      };
    case DELETE_RECRUITER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_RECRUITER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
