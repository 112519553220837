import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/user_slice";

const CommCenter = props => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }
  }, [dispatch, userData.userId]);

  return (
    <div className="home">
      <h1>Communication Center</h1>
      {!(userData && userData.userGroups.includes("coaches")) ? null : (
        <p>
          This is where you as a coach could send mass communications to players
          or to individual players. This is also where you can receive messages
          directly from recruiters who are interested in players on your team.
          We are open to feedback on this page but at the moment this is a
          placeholder.
        </p>
      )}
      {!(userData && userData.userGroups.includes("recruiters")) ? null : (
        <p>
          This section gives you the ability to directly message players and
          their coaches. We are open to features and input for this page
          including compliance requests.
        </p>
      )}
    </div>
  );
};

export default CommCenter;
