import moment from "moment";

export const enhanceData = (data, filterField, filterValue, otherField) => {
  if (data) {
    //const filterValues = filterValue ? filterValue.split(',') : [];
    return (
      data
        //.filter(r => !filterField || filterValue === 'all' || (filterValues.includes('' + r[filterField])))
        .map(r => {
          const returnval = { ...r };
          returnval.game =
            moment.utc(new Date(r.gameDate)).format("M/D/YYYY") +
            (r.gameLocation === "home" ? " vs " : " @ ") +
            r.opposingTeam;

          analyticStats.forEach(s => {
            returnval[s.value] = s.fn(r);
          });
          return returnval;
        })
    );
  } else return null;
};

export const analyticGroupings = [
  { name: "Game", value: "gameId" },
  { name: "Down", value: "down" },
  { name: "Offensive Formation", value: "off_formation", odk: "O" },
  { name: "Offensive Play", value: "off_play", odk: "O" },
  { name: "Defensive Personnel", value: "def_personnel", odk: "D" },
  { name: "Defensive Front", value: "def_front", odk: "D" },
  { name: "Defensive Coverage", value: "def_coverage", odk: "D" },
  { name: "Defensive Play", value: "def_play", odk: "D" },
  { name: "Play Direction", value: "direction" },
];

const rFormat = (a, b) => (a ? "" + a : "0") + "/" + (b ? "" + b : "0");
const pFormat = (a, b) =>
  b ? (a / b).toLocaleString("en-US", { style: "percent" }) : "--";
const dFormat = (a, b) =>
  b ? (a / b).toLocaleString("en-US", { maximumFractionDigits: 1 }) : "--";

export const analyticStats = [
  {
    name: "Total Plays",
    value: "all_plays",
    fn: rec => rec.runs + rec.passes,
    all_stats: true,
  },
  {
    name: "Total Yards",
    value: "all_yards",
    fn: rec => rec.run_yards + rec.pass_yards,
    all_stats: true,
  },
  {
    name: "Run/Pass",
    value: "run_pass",
    fn: rec => rFormat(rec.runs, rec.passes),
    all_stats: true,
  },
  {
    name: "Run Efficiency",
    value: "run_efficiency",
    fn: rec => pFormat(rec.runs_over3, rec.runs),
  },
  {
    name: "Pass Efficiency",
    value: "pass_efficiency",
    fn: rec => pFormat(rec.pass_over3, rec.passes),
  },
  {
    name: "Play Efficiency",
    value: "play_efficiency",
    fn: rec => pFormat(rec.runs_over3 + rec.pass_over3, rec.runs + rec.passes),
    all_stats: true,
  },
  {
    name: "Turnovers",
    value: "turnovers",
    fn: rec => rec.turnovers,
    all_stats: true,
  },
  {
    name: "Yards per Play",
    value: "yards_per_play",
    fn: rec => dFormat(rec.run_yards + rec.pass_yards, rec.runs + rec.passes),
    all_stats: true,
  },
  { name: "Total Runs", value: "runs", fn: rec => rec.runs },
  { name: "Total Passes", value: "passes", fn: rec => rec.passes },
  {
    name: "Pass Completions",
    value: "pass_comps",
    fn: rec => pFormat(rec.pass_comps, rec.passes),
    all_stats: true,
  },
  { name: "Negative Plays", value: "negatives", fn: rec => rec.negatives },
  { name: "Conversions", value: "conversions", fn: rec => rec.conversions },
  {
    name: "Conversion Percent",
    value: "conversion_percent",
    fn: rec => pFormat(rec.conversions, rec.runs + rec.passes),
  },
  { name: "Run Yards", value: "run_yards", fn: rec => rec.run_yards },
  { name: "Pass Yards", value: "pass_yards", fn: rec => rec.pass_yards },
  {
    name: "Yards per Run",
    value: "yards_per_run",
    fn: rec => dFormat(rec.run_yards, rec.runs),
  },
  {
    name: "Yards per Completion",
    value: "yards_per_pass",
    fn: rec => dFormat(rec.pass_yards, rec.pass_comps),
  },
];
