import React from "react";

const Messages = ({ playerId }) => {
  return (
    <>
      <p>Communications with players here.</p>
    </>
  );
};

export default Messages;
