import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import * as queryString from "query-string";

const slice = createSlice({
  name: "scoutingTeams",
  initialState: {
    loading: false,
    updating: false,
    dataBySeasonId: {},
    error: null,
  },
  reducers: {
    scoutingTeamsLoading: state => {
      state.loading = true;
      state.error = null;
    },
    getScoutingTeamsSuccess: (state, action) => {
      state.loading = false;
      state.dataBySeasonId = {
        ...state.dataBySeasonId,
        [action.payload.seasonId]: action.payload.data,
      };
      state.error = null;
    },
    getScoutingTeamsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  scoutingTeamsLoading,
  getScoutingTeamsSuccess,
  getScoutingTeamsFailure,
} = slice.actions;

export const getScoutingTeams = seasonId => async dispatch => {
  try {
    dispatch(scoutingTeamsLoading());
    console.log("getting scouting teams");
    const qs = queryString.stringify({ seasonId });
    const response = await API.get("coachAPI", `scoutingteams?${qs}`);
    console.log("got scouting teams", response);

    if (response.error) {
      dispatch(getScoutingTeamsFailure(response.error));
    } else {
      dispatch(getScoutingTeamsSuccess({ seasonId, data: response }));
    }
  } catch (e) {
    dispatch(getScoutingTeamsFailure(e));
  }
};

export const createScoutingTeam =
  (teamName, seasonId, sport) => async dispatch => {
    console.log("creating scouting team", teamName);
    await API.post("coachAPI", "scoutingteams", {
      body: { teamName, seasonId, sport },
    });
    dispatch(getScoutingTeams(seasonId));
  };
export const updateScoutingTeam =
  (seasonId, rosterId, teamName) => async dispatch => {
    console.log("updating scouting team", rosterId);
    await API.put("coachAPI", `scoutingteams/${rosterId}`, {
      body: { teamName },
    });
    dispatch(getScoutingTeams(seasonId));
  };

export const deleteScoutingTeam = (seasonId, rosterId) => async dispatch => {
  console.log("deleting scouting team", rosterId);
  await API.del("coachAPI", `scoutingteams/${rosterId}`);
  dispatch(getScoutingTeams(seasonId));
};

export const selectDataLoading = store => store.scoutingTeams.loading;

export const selectDataBySeasonId = seasonId => store => {
  try {
    return store.scoutingTeams.dataBySeasonId[seasonId];
  } catch (e) {
    return null;
  }
};
