import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Space } from "antd";
import { getSeasonstatsByFilter } from "../../redux/actions/seasonstatsActions";
import {
  selectSeasonstatColumnsByQueryString,
  selectSeasonstatsByQueryString,
  selectSeasonstatsLoading,
} from "../../redux/selectors/seasonstatsSelectors";
import { FBStatGroupingsSelect } from "../../shared-components/FBSelects";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import AddToSavedLists from "../../shared-components/addToSavedList";
import PlayerTable from "../../shared-components/PlayerTable";
import { SeasonsSelect } from "../../shared-components/seasonsSelect";
import * as qs from "query-string";
import StatGroupings from "../../shared-components/categories_fb";
import { StateSelect } from "../../shared-components/stateSelect";

const StatLeaders = props => {
  const { statGroupings, seasonId, state, sortField } = parseQueryString(
    props.location.search,
    "statGroupings",
  );
  const queryString = qs.stringify({ statGroupings, seasonId, state });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const data = useSelector(selectSeasonstatsByQueryString(queryString));

  const columns = useSelector(
    selectSeasonstatColumnsByQueryString(queryString),
  );

  const loading = useSelector(selectSeasonstatsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    // don't do anything if already loading
    if (statGroupings && seasonId && state && !loading && !data) {
      dispatch(getSeasonstatsByFilter({ statGroupings, seasonId, state }));
    }

    if (statGroupings && data && !sortField) {
      props.navigate(
        props.location.pathname +
          "?" +
          qs.stringify({
            statGroupings,
            seasonId,
            sortField:
              StatGroupings[statGroupings].statPrefix +
              StatGroupings[statGroupings].stats[0],
            sortOrder: "descend",
          }),
      );
    }
  });

  const changeStatGroupings = statGroupings => {
    // only pass statGroupings, seasonId, and state to buildQueryString to clear sorting, filters, page
    props.navigate(
      props.location.pathname +
        "?" +
        qs.stringify({ statGroupings, seasonId, state }),
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: srk => {
      setSelectedRowKeys(srk);
    },
  };

  return (
    <Layout>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <Space direction={"vertical"}>
          <h1>Stat Leaders</h1>
          <FBStatGroupingsSelect
            defaultValue={statGroupings}
            onChange={changeStatGroupings}
            width={200}
          />
          <SeasonsSelect sport={"fb"} seasonId={seasonId} />
          <StateSelect sport={"fb"} state={state} />

          <AddToSavedLists
            selectedRowKeys={selectedRowKeys}
            clearSelectedRows={() => setSelectedRowKeys([])}
            addedFrom={"stat leaders"}
            addedFromDetail={statGroupings}
          />
        </Space>
      </Layout.Content>
      <PlayerTable
        rowSelection={rowSelection}
        playerData={data}
        columns={columns}
        loading={loading}
        navBase={"/recruiters"}
      />
    </Layout>
  );
};

export default StatLeaders;
