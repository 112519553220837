import {
  CREATE_MESSAGE_FAILURE,
  CREATE_MESSAGE_STARTED,
  CREATE_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE,
  GET_MESSAGE_STARTED,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_STARTED,
  GET_MESSAGES_SUCCESS,
  GET_SENT_MESSAGES_FAILURE,
  GET_SENT_MESSAGES_STARTED,
  GET_SENT_MESSAGES_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  UPDATE_MESSAGE_STARTED,
  UPDATE_MESSAGE_SUCCESS,
} from "../actions/messageActions";

const initialState = {
  loading: false,
  syncing: false,
  error: null,
  receivedMessages: [],
  sentMessages: [],
  messagesById: {},
  usersById: {},
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        receivedMessages: action.payload.data,
      };
    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        receivedMessages: [],
      };

    case GET_SENT_MESSAGES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_SENT_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sentMessages: action.payload.data,
      };
    case GET_SENT_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        sentMessages: [],
      };

    case GET_MESSAGE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        messagesById: {
          ...state.messagesById,
          [action.payload.messageId]: action.payload.data,
        },
      };
    case GET_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case GET_USER_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        usersById: {
          ...state.usersById,
          [action.payload.id]: action.payload.data,
        },
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_MESSAGE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sentMessages: state.sentMessages.concat(action.payload.messageData),
      };
    case CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_MESSAGE_STARTED:
      return {
        ...state,
        receivedMessages: state.receivedMessages.map(m => {
          if (m.messageId === action.payload.messageId)
            return {
              ...m,
              isRead: action.payload.isRead,
              isArchived: action.payload.isArchived,
            };
          else return m;
        }),
        loading: true,
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
