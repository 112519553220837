import FlatfileWrapper from "../../../shared-components/FlatfileWrapper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRosterPlayers } from "../../../redux/actions/rosterActions";
import { getUser } from "../../../redux/user_slice";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { positionsList } from "../../../shared-components/categories_fb";

const positionOptions = positionsList.map(p => {
  return { value: p, label: p };
});
const gradOptions = [...Array(5).keys()]
  .map(i => i + new Date().getFullYear())
  .map(y => y.toString())
  .map(y => {
    return { value: y, label: y };
  });

const rosterUploadBlueprint = {
  name: "Roster Players",
  slug: "roster-players",
  readonly: false,
  allowAdditionalFields: true,
  fields: [
    {
      label: "Player Name",
      key: "playerName",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    {
      label: "Player Jersey",
      key: "jersey",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    {
      label: "Position",
      key: "position",
      type: "enum",
      config: {
        options: positionOptions,
      },
      constraints: [{ type: "required" }],
    },
    {
      label: "Graduating Class",
      key: "graduationYear",
      type: "enum",
      config: {
        options: gradOptions,
      },
      constraints: [{ type: "required" }],
    },
  ],
};

const FlatfileRosterUpload = ({ rosterId, seasonId }) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userAttributes) {
      dispatch(getUser());
    }
  });

  const transformData = data => {
    return data.map(record => {
      return {
        ...record,
        rosterId,
      };
    });
  };

  if (rosterId !== "all") {
    return (
      <>
        <FlatfileWrapper
          blueprint={rosterUploadBlueprint}
          handleSubmit={data =>
            dispatch(
              addRosterPlayers(rosterId, {
                rosterPlayers: transformData(data),
              }),
            )
          }
          buttonContent={
            <>
              Upload Roster Players
              <UsergroupAddOutlined style={{ color: "white" }} />
            </>
          }
        ></FlatfileWrapper>
      </>
    );
  } else return <></>;
};

export default FlatfileRosterUpload;
