const dev = {
  apiGateway: {
    REGION: "us-east-2",
    RECRUITER_URL: "https://bozgx2wc89.execute-api.us-east-2.amazonaws.com/",
    COMMUNICATIONS_URL:
      "https://p1v5wn0wyf.execute-api.us-east-2.amazonaws.com/dev/",
    COACH_URL: "https://e2cbrnnvxc.execute-api.us-east-2.amazonaws.com/",
    PLAYER_URL: "https://nzzob95ozl.execute-api.us-east-2.amazonaws.com/",
    USERS_URL: "https://in76wuha70.execute-api.us-east-2.amazonaws.com/dev/",
    USERADMIN_URL: "https://numkjqoso3.execute-api.us-east-2.amazonaws.com/",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_aSC44S6Io",
    APP_CLIENT_ID: "7e2paobl8qgas52n5ihik90mmr",
    IDENTITY_POOL_ID: "us-east-2:41adca8b-28bd-43a3-be1e-0eb2275a329d",
  },
  flatfile: {
    publishableKey: "pk_32a9c91cbaa94d428e43f0ccad169deb",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    RECRUITER_URL: "https://l3ni35r9z2.execute-api.us-east-2.amazonaws.com/",
    COMMUNICATIONS_URL:
      "https://bfhggmow9a.execute-api.us-east-2.amazonaws.com/prod/",
    COACH_URL: "https://zmkmurevm5.execute-api.us-east-2.amazonaws.com/",
    PLAYER_URL: "https://rfv4waw53i.execute-api.us-east-2.amazonaws.com/",
    USERS_URL: "https://36l5201ra9.execute-api.us-east-2.amazonaws.com/prod/",
    USERADMIN_URL: "https://bkt8nemyca.execute-api.us-east-2.amazonaws.com/",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_s8JbNKNF5",
    APP_CLIENT_ID: "54ghoggeoapvrevi35artsd4po",
    IDENTITY_POOL_ID: "us-east-2:e5235797-9c0a-4762-b4d5-7976a4c46dcf",
  },
  flatfile: {
    publishableKey: "pk_b2211d8c1ea842a89f06ceef1b54f50e",
  },
};

const sales = {
  apiGateway: {},
  cognito: {},
};

const staging = {
  apiGateway: {
    REGION: "us-east-2",
    RECRUITER_URL: "https://hfdc0odaeh.execute-api.us-east-2.amazonaws.com/",
    COMMUNICATIONS_URL:
      "https://13f9ooynzd.execute-api.us-east-2.amazonaws.com/staging/",
    COACH_URL: "https://p0png2dxe7.execute-api.us-east-2.amazonaws.com/",
    PLAYER_URL: "https://6mbed4rxs2.execute-api.us-east-2.amazonaws.com/",
    USERS_URL:
      "https://8klcyba4pk.execute-api.us-east-2.amazonaws.com/staging/",
    USERADMIN_URL: "https://vub68yksxf.execute-api.us-east-2.amazonaws.com/",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_of2f17AIz",
    APP_CLIENT_ID: "6ovbv6spr5465gf82okbqf5mof",
    IDENTITY_POOL_ID: "us-east-2:b5ed7afb-276a-4064-a41a-ba2d37da1943",
  },
  flatfile: {
    publishableKey: "pk_28753574d5b94412ae0e0b855d6341a6",
  },
};

// Default to dev if not set
let config;

switch (process.env.REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "sales":
    config = sales;
    break;
  case "staging":
    config = staging;
    break;
  case "dev":
    config = dev;
    break;
  default:
    config = dev;
}

const conf = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

export default conf;
