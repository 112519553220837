import React, { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGotLists,
  selectLoading,
  selectPlayerPipelineLists,
  selectLists,
} from "../redux/selectors/recruiterlistSelectors";
import { getLists } from "../redux/actions/recruiterlistActions";

const { Option, OptGroup } = Select;

export const SavedListsSelect = ({
  onChange,
  width,
  mode = "default",
  onlyShowPipelineLists = false,
}) => {
  const pipelineListOptions = useSelector(selectPlayerPipelineLists);
  const savedListOptions = useSelector(selectLists);
  const gotLists = useSelector(selectGotLists);
  const loading = useSelector(selectLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!gotLists && !loading) dispatch(getLists());
  });

  return (
    <Select
      //showSearch={true}
      mode={mode}
      placeholder="Pick a Saved List"
      style={!!width ? { width: width } : {}}
      onChange={onChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <OptGroup label="Pipeline Lists">
        options=
        {pipelineListOptions.map(d => {
          return (
            <Option key={d.listId} value={d.listId}>
              {d.listName}
            </Option>
          );
        })}
      </OptGroup>
      {!onlyShowPipelineLists ? (
        <OptGroup label="Saved Lists">
          options=
          {savedListOptions.map(d => {
            return (
              <Option key={d.listId} value={d.listId}>
                {d.listName}
              </Option>
            );
          })}
        </OptGroup>
      ) : null}
    </Select>
  );
};
