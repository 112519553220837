export const selectPlayerProfileState = store => store.playerProfileData;

export const selectPlayerLinksByPlayerId = playerId => store =>
  selectPlayerProfileState(store)
    ? selectPlayerProfileState(store).linksByPlayerId[playerId]
    : [];

export const selectPlayerAboutsByPlayerId = playerId => store =>
  selectPlayerProfileState(store)
    ? selectPlayerProfileState(store).aboutsByPlayerId[playerId]
    : null;

export const selectPlayerProfiledataByPlayerId = playerId => store =>
  selectPlayerProfileState(store)
    ? selectPlayerProfileState(store).profiledataByPlayerId[playerId]
    : [];

export const selectPlayerProfileLoading = store =>
  selectPlayerProfileState(store)
    ? selectPlayerProfileState(store).loading
    : false;

export const selectMyPlayerIds = store =>
  selectPlayerProfileState(store)
    ? selectPlayerProfileState(store).myPlayerIds
    : [];
