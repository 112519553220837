import React from "react";
import { DEPTH_CHART_LEVELS } from "../../../shared-components/categories_fb";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const DepthChartToPdf = ({ activeDepthByChartId }) => {
  const offenseLevels = DEPTH_CHART_LEVELS["offense"];
  const defenseLevels = DEPTH_CHART_LEVELS["defense"];
  const specialTeamsLevels = DEPTH_CHART_LEVELS["specialTeams"];
  const offensePositions = {};
  const defensePositions = {};
  const specialTeamsPositions = {};

  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    section: {
      paddingLeft: 20,
      paddingTop: 15,
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#112131",
    },
    container: {
      flexDirection: "row",
      borderBottomWidth: 2,
      borderBottomColor: "#112131",
      borderBottomStyle: "solid",
      alignItems: "stretch",
    },
    name: {
      fontSize: 24,
    },
    player: {
      fontSize: 10,
    },
    title: {
      fontSize: 15,
      borderBottomWidth: 1,
      borderBottomColor: "#112131",
    },
    positionSection: {
      paddingLeft: 10,
      flexDirection: "row",
      margin: 10,
      height: 100,
    },
    position: {
      padding: 5,
      marginRight: 10,
      width: 120,
    },
  });

  Object.entries(activeDepthByChartId).forEach(([k, v]) => {
    if (offenseLevels.includes(k)) {
      offensePositions[k] = v;
    }

    if (defenseLevels.includes(k)) {
      defensePositions[k] = v;
    }

    if (specialTeamsLevels.includes(k)) {
      specialTeamsPositions[k] = v;
    }
  });

  const chunk = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size),
    );
  };

  const pageDetailsByLevel = (levels, positions) => {
    return levels.map(level => {
      return (
        <>
          <View style={styles.section}>
            <Text>{level}</Text>
          </View>
          {positions[level]
            ? chunk(Object.keys(positions[level]), 4).map(positionRow => {
                return (
                  <View key={positionRow} style={styles.positionSection}>
                    {positionRow.map(position => {
                      return (
                        <View key={position} style={styles.position}>
                          <View>
                            <Text style={styles.title}>{position}</Text>
                          </View>
                          <View>
                            {positions[level][position].map(player => {
                              return (
                                <Text
                                  key={`${position}${player.playerId}`}
                                  style={styles.player}
                                >
                                  {player.playerName}
                                </Text>
                              );
                            })}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                );
              })
            : null}
        </>
      );
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View>
            <Text style={styles.name}>Offense</Text>
          </View>
        </View>
        {pageDetailsByLevel(offenseLevels, offensePositions)}
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View>
            <Text style={styles.name}>Defense</Text>
          </View>
        </View>
        {pageDetailsByLevel(defenseLevels, defensePositions)}
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View>
            <Text style={styles.name}>Special Teams</Text>
          </View>
        </View>
        {pageDetailsByLevel(specialTeamsLevels, specialTeamsPositions)}
      </Page>
    </Document>
  );
};

export default DepthChartToPdf;
