import React from "react";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import RosterTabs from "./components/RosterTabs";
import EditSchedule from "./components/EditSchedule";
import { Col, Layout } from "antd";

const Schedule = ({ testRosterId, testSeasonId, ...props }) => {
  const rosterId = testRosterId
    ? testRosterId
    : parseQueryString(props.location.search, "rosterId")["rosterId"];
  const seasonId = testSeasonId
    ? testSeasonId
    : parseQueryString(props.location.search)["seasonId"];

  return (
    <Layout>
      <Layout.Content style={{ marginBottom: 72 }}>
        <div className="center">
          <h1>Schedule Management</h1>

          <RosterTabs
            rosterId={rosterId}
            hideInactive={true}
            hideScouting={true}
          />
          <Col span={18}>
            <EditSchedule rosterId={rosterId} seasonId={seasonId} />
          </Col>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Schedule;
