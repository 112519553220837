import { API } from "aws-amplify";

export const GET_RECRUITER_LISTS_STARTED = "GET_RECRUITER_LISTS_STARTED";
export const GET_RECRUITER_LISTS_SUCCESS = "GET_RECRUITER_LISTS_SUCCESS";
export const GET_RECRUITER_LISTS_FAILURE = "GET_RECRUITER_LISTS_FAILURE";

export const GET_RECRUITER_LIST_STARTED = "GET_RECRUITER_LIST_STARTED";
export const GET_RECRUITER_LIST_SUCCESS = "GET_RECRUITER_LIST_SUCCESS";
export const GET_RECRUITER_LIST_FAILURE = "GET_RECRUITER_LIST_FAILURE";

export const CREATE_RECRUITER_LIST_STARTED = "CREATE_RECRUITER_LIST_STARTED";
export const CREATE_RECRUITER_LIST_SUCCESS = "CREATE_RECRUITER_LIST_SUCCESS";
export const CREATE_RECRUITER_LIST_FAILURE = "CREATE_RECRUITER_LIST_FAILURE";

export const UPDATE_RECRUITER_LIST_STARTED = "UPDATE_RECRUITER_LIST_STARTED";
export const UPDATE_RECRUITER_LIST_SUCCESS = "UPDATE_RECRUITER_LIST_SUCCESS";
export const UPDATE_RECRUITER_LIST_FAILURE = "UPDATE_RECRUITER_LIST_FAILURE";

export const DELETE_RECRUITER_LIST_STARTED = "DELETE_RECRUITER_LIST_STARTED";
export const DELETE_RECRUITER_LIST_SUCCESS = "DELETE_RECRUITER_LIST_SUCCESS";
export const DELETE_RECRUITER_LIST_FAILURE = "DELETE_RECRUITER_LIST_FAILURE";

export function getLists() {
  console.log("getting lists");
  return async dispatch => {
    try {
      dispatch(getListsStarted());
      const lists = await API.get("recruiterAPI", `lists`);
      dispatch(getListsSuccess(lists));
    } catch (e) {
      dispatch(getListsFailure(e));
    }
  };
}

const getListsSuccess = (data = []) => ({
  type: GET_RECRUITER_LISTS_SUCCESS,
  payload: { data },
});

const getListsStarted = () => ({
  type: GET_RECRUITER_LISTS_STARTED,
});

const getListsFailure = error => ({
  type: GET_RECRUITER_LISTS_FAILURE,
  payload: { error },
});

export function getListById(listId) {
  console.log("getting list:", listId);
  return async dispatch => {
    try {
      dispatch(getListByIdStarted());
      const list = await API.get("recruiterAPI", `lists/${listId}`);
      dispatch(getListByIdSuccess(listId, list));
    } catch (e) {
      dispatch(getListByIdFailure(e));
    }
  };
}

const getListByIdSuccess = (listId, data = {}) => ({
  type: GET_RECRUITER_LIST_SUCCESS,
  payload: { listId, data },
});

const getListByIdStarted = () => ({
  type: GET_RECRUITER_LIST_STARTED,
});

const getListByIdFailure = error => ({
  type: GET_RECRUITER_LIST_FAILURE,
  payload: { error },
});

export function createList(listData) {
  // expects listData = { listName: ..., playerIds: [...] }
  // api returns { listId: ... }
  console.log("creating list", listData);
  return async dispatch => {
    try {
      dispatch(createListStarted());
      const list = await API.post("recruiterAPI", `lists`, {
        body: listData,
      });
      dispatch(getLists());
      dispatch(createListSuccess(listData, list));
    } catch (e) {
      dispatch(createListFailure(e));
    }
  };
}

const createListSuccess = (listData, data = {}) => ({
  type: CREATE_RECRUITER_LIST_SUCCESS,
  payload: { listData, data },
});

const createListStarted = () => ({
  type: CREATE_RECRUITER_LIST_STARTED,
});

const createListFailure = error => ({
  type: CREATE_RECRUITER_LIST_FAILURE,
  payload: { error },
});

export function updateList(listId, listData, dispatchOnSuccess = null) {
  // expects listData = { additions: [...], deletions: [...] }
  console.log("updating list", listId, listData);
  return async dispatch => {
    try {
      dispatch(updateListStarted(listId, listData));
      await API.put("recruiterAPI", `lists/${listId}`, {
        body: listData,
      });
      dispatch(getLists());
      if (dispatchOnSuccess) dispatch(dispatchOnSuccess(`listId=${listId}`));
      dispatch(updateListSuccess());
    } catch (e) {
      return dispatch(updateListFailure(e));
    }
  };
}

export function updateListOrder(listData, dispatchOnSuccess = null) {
  console.log("updating pipeline list Order", listData);
  return async dispatch => {
    try {
      await API.put("recruiterAPI", `listOrder`, {
        body: listData,
      });
      dispatch(getLists());
      if (dispatchOnSuccess)
        dispatch(dispatchOnSuccess({ listData: listData }));
      return dispatch(updateListSuccess());
    } catch (e) {
      return dispatch(updateListFailure(e));
    }
  };
}

const updateListSuccess = () => ({
  type: UPDATE_RECRUITER_LIST_SUCCESS,
});

const updateListStarted = (listId, listData) => ({
  type: UPDATE_RECRUITER_LIST_STARTED,
  payload: { listId, listData },
});

const updateListFailure = error => ({
  type: UPDATE_RECRUITER_LIST_FAILURE,
  payload: { error },
});

export function deleteList(listId) {
  console.log("deleting list", listId);
  return async dispatch => {
    try {
      dispatch(deleteListStarted(listId));
      await API.del("recruiterAPI", `lists/${listId}`);
      dispatch(getLists());
      dispatch(deleteListSuccess());
    } catch (e) {
      dispatch(deleteListFailure(e));
    }
  };
}

const deleteListSuccess = () => ({
  type: DELETE_RECRUITER_LIST_SUCCESS,
});

const deleteListStarted = listId => ({
  type: DELETE_RECRUITER_LIST_STARTED,
  payload: { listId },
});

const deleteListFailure = error => ({
  type: DELETE_RECRUITER_LIST_FAILURE,
  payload: { error },
});
