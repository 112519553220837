import { useDispatch, useSelector } from "react-redux";
import {
  getTeamPerformanceCapabilitiesByRosterId,
  selectTeamCapabilitiesDataByRosterId,
  selectTeamCapabilitiesDataLoading,
} from "../../../redux/teamPerformanceCapabilites_slice";
import { useEffect } from "react";
import TeamAnalytics from "./TeamAnalytics";
import PlayerAnalytics from "./PlayerAnalytics";

const TeamPerformanceCapabilities = ({ rosterId }) => {
  const teamCapabilities = useSelector(
    selectTeamCapabilitiesDataByRosterId(rosterId),
  );

  const teamCapabilitiesLoading = useSelector(
    selectTeamCapabilitiesDataLoading,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (rosterId) {
      dispatch(getTeamPerformanceCapabilitiesByRosterId(rosterId));
    }
  }, [rosterId]);

  return (
    <>
      <TeamAnalytics rosterId={rosterId} />
      <PlayerAnalytics rosterId={rosterId} />
    </>
  );
};

export default TeamPerformanceCapabilities;
